import React, { Fragment } from "react";
import PropTypes from "prop-types";


const trackRadius = 1
const handleRadius = 5
const virtualHandleRadius = 10


function MyRail({ getRailProps }) {
  return (
    <Fragment>
      <div style={{
        width: "100%",
        height: 2*virtualHandleRadius,
        top: -virtualHandleRadius,
        position: "absolute",
        cursor: "pointer"}} {...getRailProps()} />
      <div style={{backgroundColor: "#121212",
        width: "100%",
        height: 2*trackRadius,
        top: -trackRadius,
        position: "absolute",
        cursor: "pointer",}} />
    </Fragment>
  )
}

function MyHandle({
  handle: { id, value, percent },
  getHandleProps
}) {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: 'absolute',
        zIndex: 2,
        width: 2*handleRadius,
        height: 2*handleRadius,
        top: -handleRadius,
        transform: `translateX(${-handleRadius}px)`,
        border: 0,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: "#bb86fc",
        text: "none",
      }}
      {...getHandleProps(id)}
    />
  )
}

function MyTrack({ source, target, getTrackProps }) {
  const left = `${source.percent}%`
  const width = `${target.percent - source.percent}%`

  return (
    <Fragment>
      <div style={{ left, width,
        backgroundColor: "#bb86fc",
        height: 2*trackRadius,
        top: -trackRadius,
        position: "absolute",
        zIndex: 1,
        pointerEvents: "none" }} />
      <div
        style={{ left, width,
        height: 2*virtualHandleRadius,
        top: virtualHandleRadius * -1,
        position: "absolute",
        cursor: "pointer" }}
        {...getTrackProps()}
      />
    </Fragment>
  )
}

export function MyTick({ tick, count, format }) {
  return (
    <div>
      <div style={{
        left: `${tick.percent}%`,
        transform: `translateX(${-0.5}px)`,
        marginTop: "10px",
        height: "10px",
        width: "1px",
        borderRadius: "2px",
        backgroundColor: "white",
        position: "absolute",
        marginTop: 14,
        width: 1,
        height: 5,
      }} />
      <div
        variant="caption"
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          transform: `translateX(${-0.5}px)`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
          color: "white",
          position: "absolute",
          marginTop: 22,
          textAlign: "center"
        }}
      >
        {tick.value}
      </div>
    </div>
  );
}

export default { MyRail, MyHandle, MyTrack, MyTick }