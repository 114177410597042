import React, {useEffect} from "react"

import {Container, Row, Col} from "react-bootstrap"

import "./NotFoundPage.css"
import "../../global.css"

import { ScrollToTopOnMount } from '../../functions/ScrollToTop'

function NotFoundPage() {
  useEffect(() => {
    document.title = "Calimath - 404"
  })

  return (
    <Container fluid>
      <ScrollToTopOnMount/>
      <Row>
        <Col>
        <div className="content-box title404">
          Error 404. The page you are trying to access does not exist.
        </div>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFoundPage