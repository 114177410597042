import React, {useEffect} from 'react'
import { Container } from 'react-bootstrap'
import { Element } from 'react-scroll'

import ContactForm from '../../elements/ContactForm/ContactForm'

import "./About.css"

import { ScrollToTopOnMount } from '../../functions/ScrollToTop'
 
function About () {
  useEffect(() => {
    document.title = "Calimath - About us"
  }, [])

  return (
    <Container fluid>
      <ScrollToTopOnMount/>
      <div className="content-box">
        <h1>
          About us
        </h1>
        We are Richard and Timo, the co-hosts of calimath. We both started participating in math competitions at an early age. Richard later took part in other competitions as well. We both had several successes during our time in school, which led to Richard participating in the IPhO and ISEF and Timo in the IMO twice. We both had great fun with student math olympiads, but we can't participate anymore since we are both university students in Bonn, Germany. Therefore, we decided to start calimath, a new online platform for learning about olympiad mathematics. The cool part about doing this ourselves is that we can design everything to our ideals, which are:
        <ul>
          <li>
            calimath should be aesthetically pleasing and straightforward both in a visual and literary sense,
          </li>
          <li>
            calimath should serve as a better search engine, as it is difficult to find high-quality problems appropriate for your level and  maybe even concerning a specific topic,
          </li>
          <li>
            we want to make calimath self-contained. Since that is outside our scope, we provide links to good external resources whenever we use results and definitions that we do not go into detail on ourselves.
          </li>
        </ul>
      </div>
      <div className="content-box">
        <h1>
          Contact us
        </h1>
        <Element name="contact-form">
          <ContactForm />
        </Element>
      </div>
    </Container>
    )
}

export default About