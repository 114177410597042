import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'

import {GiOpenBook} from "react-icons/gi"

import {Container, Row, Col} from "react-bootstrap"

import MyTooltip from '../MyTooltip/MyTooltip'

import "./MyButton.css"
import "../../global.css"
import "../../main.css"

import { toPath } from "../../functions/toPath"



function WikiButton({article, setShowMoreId}) {
  return (
    <div className="button">
      <Container fluid>
        <Row>
          <Col className="main-col">
            <span className="main-col-box">
              <Link to={"/wiki/" + toPath(article.title)} className="icon-box">
                <MyTooltip text={<div>Open wiki article</div>} placement={"top"} arrow={false} slow={true}>
                  <div>
                    <GiOpenBook size={70} className="clickable-icon"/>
                  </div>
                </MyTooltip>
              </Link>
              <Link to={"/wiki/" + toPath(article.title)} className="title-link">
                <div className="title-box">
                  {article.title}
                </div>
              </Link>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WikiButton