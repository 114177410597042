import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom"
import {FormGroup, Label, Input} from 'reactstrap';
import {Row, Col} from "react-bootstrap"

import sha256 from 'crypto-js/sha256';

import {FaBars} from "react-icons/fa"
import {AiOutlineClose} from "react-icons/ai"

import {NavbarDataUp, NavbarDataDown, NavbarDataAdmin} from "../../data/NavbarData"

import Switch from "../../elements/Switch/Switch"
import MyTooltip from '../../elements/MyTooltip/MyTooltip'

import CalimathLogo from "../../img/drawing-dark.png"
import "./Navbar.css"
import { NewFeature } from '../../elements/ForegroundStuff/ForegroundStuff';

function Navbar({navbarActive, setNavbarActive, admin, setAdmin, adminBox, setAdminBox, customDate, toggleCustomDate, year, setYear, month, setMonth, day, setDay, hour, setHour, minute, setMinute, setDateOf, fullWiki, toggleFullWiki, showThumbnail, toggleShowThumbnail, showChannelDetails, toggleShowChannelDetails}) {

  const adminHash = "3f1006a5bf7d3a20a1842ce9f2c617f5d66f2b9ec65bbeefa27c58c2915ce9cd"

  useEffect(() => {
    setDateOf(year, month, day, hour, minute)
  }, [year, month, day, hour, minute])

  const showBulletPoints = bulletPoints => {
    return <>
      {bulletPoints.map((item, index) => {
        return <div className="bulletpoint" key={index}>
          {item.path !== undefined ? (
              <li key={index} className={item.cName}>
                <Link to={item.path} onClick={() => setNavbarActive(false)} onTouchEnd={() => setNavbarActive(false)}>
                  {item.icon} &nbsp; {item.title} &nbsp; {item.titleIcon}
                </Link>
              </li>
            ) 
          :
            (
              <li key={index} className={item.cName}>
                <a target="_blank" rel="noreferrer" href={item.url} onClick={() => setNavbarActive(false)} onTouchEnd={() => setNavbarActive(false)}>
                  {item.icon} &nbsp; {item.title} &nbsp; {item.titleIcon}
                </a>
              </li>
            )
          }
          {
            item.brandNew !== true ? <></> :
              <NewFeature />
          }
      </div>
      })}
    </>
  }
  
  return (
    <>
      <header>
        <Link to="#" className="menu-bars">
          <FaBars onClick={() => setNavbarActive(!navbarActive)} className="solo-icon"/>
        </Link>
        {
        admin ?
          <span className="header-center admin">
            <div className="switch-div">
              Admin mode
              <Switch active={admin} toggle={() => setAdmin(false, 12)}/>
            </div>
            <div className="switch-div">
              UTC Time
              <Switch active={customDate} toggle={toggleCustomDate}/>
            </div>
            {customDate ?
              <Row>
                <Col className="form-col">
                  <FormGroup>
                    <Label>
                      Day
                    </Label>
                    <Input defaultValue={day} id="day" name="day" onBlur={e => {
                      const newDay = e.target.value
                      setDay(newDay, 12)
                    }}/>
                  </FormGroup>
                </Col>
                <Col className="form-col">
                  <FormGroup>
                    <Label>
                      Month
                    </Label>
                    <Input defaultValue={month} id="month" name="month" onBlur={e => {
                      const newMonth = e.target.value
                      setMonth(newMonth, 12)
                    }}/>
                  </FormGroup>
                </Col>
                <Col className="form-col">
                  <FormGroup>
                    <Label>
                      Year
                    </Label>
                    <Input defaultValue={year} id="year" name="year" onBlur={e => {
                      const newYear = e.target.value
                      setYear(newYear, 12)
                    }}/>
                  </FormGroup>
                </Col>
                <Col className="form-col">
                  <FormGroup>
                    <Label>
                      Hour
                    </Label>
                    <Input defaultValue={hour} id="time" name="time" onBlur={e => {
                      const newHour = e.target.value
                      setHour(newHour, 12)
                    }}/>
                  </FormGroup>
                </Col>
                <Col className="form-col">
                  <FormGroup>
                    <Label>
                      Minute
                    </Label>
                    <Input defaultValue={minute} id="time" name="time" onBlur={e => {
                      const newMinute = e.target.value
                      setMinute(newMinute, 12)
                    }}/>
                  </FormGroup>
                </Col>
              </Row>
            :
            <div>
              Using current date
            </div>
            }
            <div className="switch-div">
              Full Wiki
              <Switch active={fullWiki} toggle={toggleFullWiki}/>
            </div>
            <div className="switch-div">
              Show Thumbnail
              <Switch active={showThumbnail} toggle={toggleShowThumbnail}/>
            </div>
            <div className="switch-div">
              Show Channel Details
              <Switch active={showChannelDetails} toggle={toggleShowChannelDetails}/>
            </div>
          </span> 
        :
          adminBox ?
            <span className="header-center normal">
              <div>
                Enter admin key! &nbsp;
                <MyTooltip placement="top" arrow={false} slow={true} text="Close admin box.">
                  <span className="primary-text" onClick={() => setAdminBox(false)}>
                    <AiOutlineClose/>
                  </span>
                </MyTooltip>
              </div>
              <Input type="password" placeholder="Admin key" name="admin-key" id="admin-key" onKeyDown={e => {
                  if (e.key === "Enter") {
                    const hash = sha256(e.target.value).toString()
                    if (hash === adminHash) {
                      setAdmin(true, 12)
                    }
                    e.target.value = ""
                  }
                }}/>
            </span>
          :
            <></>
        }
        <div className="header-right">
          <Link to="/" className="menu-logo" onClick={() => {
            window.scrollTo(0, 0)
            window.scrollTo(0, 0)
          }}>
            <img src={CalimathLogo} alt="Calimath logo" className="channel-logo"/>
          </Link>
        </div>
      </header>
      <nav className={navbarActive ? "nav-menu active" : "nav-menu"}>
          <div>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars" onClick={() => setNavbarActive(false)} onTouchEnd={() => setNavbarActive(false)}>
                <AiOutlineClose className="solo-icon"/>
              </Link>
            </li>
            {showBulletPoints(NavbarDataUp)}
            {!admin ? <></> :
              showBulletPoints(NavbarDataAdmin)
            }
          </div>
          <div>
            {showBulletPoints(NavbarDataDown)}
          </div>
      </nav>
    </>
  )
}

export default Navbar