import React, { useState, useEffect, useRef } from 'react'

import { Container, Row, Col } from "react-bootstrap"
import { ScrollToTopOnMount } from '../../functions/ScrollToTop'
import { toPath } from '../../functions/toPath'
import NotFoundPage from '../NotFoundPage/NotFoundPage'

import "./SkillPage.css"
import "../../global.css"

import PdfData from '../../data/PdfData'
import { SkillPageMenuData } from '../../data/SkillPageMenuData'
import SkillField from '../../elements/SkillField/SkillField'
import { publicTime } from '../../functions/myTime'
import PdfButton from '../../elements/MyButton/PdfButton'
import LectureHead from '../../elements/LectureHead/LectureHead'

import { getUniqueId } from '../../functions/getUniqueId'

import { useLocation } from 'react-router-dom'
import WikiData from '../../data/WikiData'
import SkillsData from '../../data/SkillsData'

function SkillPage({ admin, customDate, date, showThumbnail }) {
    const pdfRefs = useRef({})
    const infoRefs = useRef({})

    const location = useLocation()


    const pathToPage = (pathTitle) => {

        const page = Object.keys(SkillPageMenuData).map(key => SkillPageMenuData[key]).filter(page => {
            return page.public || admin
        }).find(page => (pathTitle === toPath(page.title)))

        setThrow404(typeof page === "undefined")

        return page
    }

    const [page, setPage] = useState(undefined)
    const [throw404, setThrow404] = useState(false)
    const [pdfs, setPdfs] = useState([])
    const [showMoreId, setShowMoreId] = useState(-1)
    const [activeId, setActiveId] = useState(-1)
    const [showMoreInfoId, setShowMoreInfoId] = useState(-1)
    const [activeInfoId, setActiveInfoId] = useState(-1)

    const updateShowMoreInfoId = (id) => {
        setShowMoreId(-1)
        if (id === showMoreInfoId) {
            setShowMoreInfoId(-1)
        } else {
            setShowMoreInfoId(id)
        }
    }

    const updateShowMoreId = (id) => {
        setShowMoreInfoId(-1)
        if (id === showMoreId) {
            setShowMoreId(-1)
        } else {
            setShowMoreId(id)
        }
    }

    const getUnfilteredSkillData = () => {
        return
    }

    useEffect(() => {
        setPage(pathToPage(location.pathname.split("/")[2]))
    }, [])

    useEffect(() => {
        setActiveId(showMoreId)
    }, [showMoreId, pdfs])

    useEffect(() => {
        if (showMoreId !== -1) {
            const headerOffset = 85
            const marginOffset = 0
            const elementPosition = pdfRefs.current[showMoreId].getBoundingClientRect().top
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset - marginOffset
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }, [activeId])

    useEffect(() => {
        setActiveInfoId(showMoreInfoId)
    }, [showMoreInfoId])

    useEffect(() => {
        if (page !== undefined) {
            document.title = "Calimath - " + page.title
        }
    }, [page, customDate, date])

    useEffect(() => {
        if (showMoreInfoId !== -1) {
            const offset = 84
            const elementPosition = infoRefs.current[showMoreInfoId].getBoundingClientRect().top
            const offsetPosition = elementPosition + window.pageYOffset - offset
            window.scrollTo({
                top: offsetPosition,
                behavior: "instant"
            });
        }
    }, [activeInfoId])

    useEffect(() => {
        if (page !== undefined) {
            setPdfs(Object.keys(PdfData).map(key => {
                return PdfData[key]()
            }).filter(file => {
                return file.infoTags.map(info => info.title).some(title => page.infoTags.map(info => info.title).includes(title))
            }).filter(file => {
                return publicTime(customDate, date, file.releaseTime)
            }))
        }
    }, [page, customDate, date])

    useEffect(() => {
        if (!admin) {
            window.scrollTo(0, 0)
        }
    }, [page])

    useEffect(() => {
        if (page === undefined) return
        const subpath = location.pathname.slice(13 + toPath(page.title).length)

        if (subpath === "") {
            return
        }

        let skill = Object.keys(WikiData).map(key => WikiData[key]()).find(entry => {
            return toPath(entry.title) === subpath
        })

        if (skill === undefined) {
            skill = Object.keys(SkillsData).map(key => SkillsData[key]()).find(entry => {
                return toPath(entry.title) === subpath
            })
        }

        if (skill === undefined) {
            setThrow404(true)
            return
        }

        setShowMoreInfoId(getUniqueId(skill))

    }, [page])

    return (throw404 ?
        <NotFoundPage />
        :
        (typeof page === "undefined" ? <></> :
            <Container fluid>
                <ScrollToTopOnMount />
                <Row>
                    <Col xs="12">
                        <div className="content-box">
                            <Row>
                                <h1 className="problems-title-col">
                                    Skillpage - {page.title}
                                </h1>
                            </Row>
                            <Row id="skills-row">
                                {false ? <></> :
                                    page.content.map((skill) =>
                                        <Col key={skill.id} xs={12}>
                                            <SkillField admin={admin} customDate={customDate} date={date} skill={skill} showMoreInfoId={showMoreInfoId} updateShowMoreInfoId={updateShowMoreInfoId} infoRefs={infoRefs} />
                                        </Col>)
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        {pdfs.length === 0 ? <></> :
                            <div className="content-box">
                                <Row>
                                    <Col xs="12">
                                        <h2>
                                            Related problems:
                                        </h2>
                                    </Col>
                                    {false ? <></> :
                                        pdfs.map(file => {
                                            return (
                                                <Col lg="6" xxl="4" className="pdf-col" key={file.id}>
                                                    <PdfButton admin={admin} id={file.id} pdfRefs={pdfRefs} showMoreId={showMoreId} updateShowMoreId={updateShowMoreId} file={file} getOnClick={() => 0} problemSearch={false} problemDelete={false} />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        }
                    </Col>
                </Row>
                {(!showThumbnail || page.lectureHeads === undefined) ? <></> :
                    <Row>
                        {page.lectureHeads.map((head, index) => {
                            return (
                                <Col className="pdf-col">
                                    <LectureHead data={head} section={index + 1} />
                                </Col>
                            )
                        })}
                    </Row>
                }
            </Container>
        )
    )
}


export default SkillPage