import React, {useRef} from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import "./MyTooltip.css"
import "../../global.css"
import "../../main.css"


function MyTooltip(props) {
  const {text, placement, arrow, slow, children} = props

  const tooltip = (text) => (
    <Tooltip className={"my-tooltip " + placement}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement={placement} overlay={tooltip(text)}
    delay={{ show: 700, hide: 1000 }}>
      {children}
    </OverlayTrigger>
  );

}


export default MyTooltip