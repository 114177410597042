import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap"


import PdfButton from "../MyButton/PdfButton"
import WikipediaButton from '../MyButton/WikipediaButton'

import { publicTime } from '../../functions/myTime'

import PdfData from '../../data/PdfData'


import "./WikiPage.css"
import "../../global.css"

import { Comment } from "../../elements/SpecialText/SpecialText"
import WikiInner from '../WikiInner/WikiInner'

function WikiPage({ admin, page, customDate, date }) {
    const pdfRefs = useRef({})

    const [pdfs, setPdfs] = useState([])
    const [showMoreId, setShowMoreId] = useState(-1)
    const [activeId, setActiveId] = useState(-1)

    const updateShowMoreId = (id) => {
        if (id === showMoreId) {
            setShowMoreId(-1)
        } else {
            setShowMoreId(id)
        }
    }

    useEffect(() => {
        setActiveId(showMoreId)
    }, [showMoreId, pdfs])

    useEffect(() => {
        if (showMoreId !== -1) {
            const headerOffset = 80
            const marginOffset = 18
            const elementPosition = pdfRefs.current[showMoreId].getBoundingClientRect().top
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset - marginOffset
        }
    }, [activeId])

    useEffect(() => {
        setPdfs(Object.keys(PdfData).map(key => {
            return PdfData[key]()
        }).filter(file => {
            return file.wikiCitations.map(wiki => wiki().id).includes(page.id)
        }).filter(file => {
            return publicTime(customDate, date, file.releaseTime)
        }))
    }, [page, customDate, date])

    useEffect(() => {
        if (!admin) {
            console.log("wikipage" + admin)
            window.scrollTo(0, 0)
        }
    }, [page])

    return (
        <div>
            <div className="content-box">
                <Col xs="12">
                    <h1>
                        {page.title}
                    </h1>
                    {page.subtitle === undefined ? <></> :
                        <div id="wiki-page-subtitle">
                            <Comment>({page.subtitle})</Comment>
                        </div>
                    }
                </Col>
                <Col xs="12">
                    <Row>
                        <Col xs="12">
                            <WikiInner page={page} />
                        </Col>
                        {typeof page.source === "undefined" ? <></> :
                            <>
                                <h2>
                                    Comprehensive external resource:
                                </h2>
                                <Col xs="12">
                                    <WikipediaButton source={page.source} />
                                </Col>
                            </>
                        }
                        {pdfs.length === 0 ? <></> :
                            <>
                                <Col xs="12">
                                    <h2>
                                        Cited in the following solutions:
                                    </h2>
                                </Col>
                                {
                                    pdfs.map(file => {
                                        return (
                                            <Col lg="6" xxl="4" className="pdf-col" key={file.id}>
                                                <PdfButton admin={admin} id={file.id} pdfRefs={pdfRefs} showMoreId={showMoreId} updateShowMoreId={updateShowMoreId} file={file} getOnClick={() => 0} problemSearch={false} problemDelete={false} />
                                            </Col>
                                        )
                                    })
                                }
                            </>
                        }
                    </Row>
                </Col>
            </div>
        </div>
    )
}

export default WikiPage