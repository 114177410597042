import {useState} from 'react'

const getItem = key =>
  document.cookie.split("; ").reduce((total, currentCookie) => {
    const item = currentCookie.split("=")
    const storedKey = item[0]
    const storedValue = item[1]
    return key === storedKey ? JSON.parse(storedValue) : total
  }, '')

const setItem = (key, value, numberOfHours) => {
  const now = new Date()
  now.setTime(now.getTime() + (numberOfHours * 60 * 60 * 24))
  document.cookie = `${key}=${JSON.stringify(value)};     expires=${now.toUTCString()}; path=/`
};

const useCookie = (key, defaultValue) => {
  const getCookie = () => getItem(key) || defaultValue
  const [cookie, setCookie] = useState(getCookie())
  const updateCookie = (value, numberOfHours) => {
      setCookie(value)
      setItem(key, value, numberOfHours)
  }
  return [cookie, updateCookie]
};

export default useCookie