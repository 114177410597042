/* global $ */

import React, { useState, useEffect } from 'react'

import { Container, Row, Col } from "react-bootstrap"

import MyTooltip from '../MyTooltip/MyTooltip'
import { FaMedal } from "react-icons/fa"

import "./MyButton.css"
import "../../global.css"
import "../../main.css"



function SkillPageButton({ data, navigateToPage }) {

    return (
        <div className="button">
            <Container fluid className="button-container">
                <div className='main-col'>
                    <div onClick={() => navigateToPage(data)} className="icon-box">
                        <MyTooltip text={<div>Open Skillpage</div>} placement={"top"} key="tooltip-solution">
                            <div>
                                <FaMedal size={70} className="clickable-icon" />
                            </div>
                        </MyTooltip>
                    </div>
                    <div onClick={() => navigateToPage(data)} className="title-box">
                        {data.title}
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default SkillPageButton