import React, { useEffect, useState, useRef } from 'react'

import "./Thumbnail.css"
import "../../global.css"

import CalimathLogoWhite from "../../img/difficulty-logos/whitelogo.png"
import CalimathLogoElementary from "../../img/difficulty-logos/elementary.png"
import CalimathLogoMedium from "../../img/difficulty-logos/medium.png"
import CalimathLogoIntermediate from "../../img/difficulty-logos/intermediate.png"
import CalimathLogoAdvanced from "../../img/difficulty-logos/advanced.png"
import CalimathLogoExpert from "../../img/difficulty-logos/expert.png"
import CalimathLogoFoundationCourse from "../../img/difficulty-logos/foundation-course.png"

import BackgroundAlgebra from "../../img/thumbnail-backgrounds/BackgroundN_p.png"

import exportAsImage from '../../functions/exportAsImage'
import { toPath } from '../../functions/toPath'

function Thumbnail({ data }) {
    const exportRef = useRef();

    const [src, setSrc] = useState(CalimathLogoIntermediate)
    const [darkmode, setDarkmode] = useState((data.darkmode === undefined) ? false : data.darkmode)
    const [thumbnailWhite, setThumbnailWhite] = useState((data.thumbnailWhite === undefined) ? false : data.thumbnailWhite)
    const [showDifficulty, setShowDifficulty] = useState((data.showDifficulty === undefined) ? true : data.showDifficulty)
    const [difficultyString, setDifficultyString] = useState("")
    const [branchString, setBranchString] = useState("")

    useEffect(() => {
        if (data.branch.tag === "A") {
            setBranchString("algebra")
        } else if (data.branch.tag === "C") {
            setBranchString("combinatorics")
        } else if (data.branch.tag === "G") {
            setBranchString("geometry")
        } else {
            setBranchString("number-theory")
        }
    })

    useEffect(() => {
        if (data.foundationCourse === true) {
            setDifficultyString("foundation course")
            setSrc(CalimathLogoFoundationCourse)
            return
        }
        if (data.showDifficulty === false) {
            setDifficultyString("")
            setSrc(CalimathLogoWhite)
            return
        }
        if (data.difficulty.title <= 5) {
            setDifficultyString("elementary")
            setSrc(CalimathLogoElementary)
            return
        }
        if (data.difficulty.title <= 7) {
            setDifficultyString("medium")
            setSrc(CalimathLogoMedium)
            return
        }
        if (data.difficulty.title <= 9) {
            setDifficultyString("intermediate")
            setSrc(CalimathLogoIntermediate)
            return
        }
        if (data.difficulty.title <= 11) {
            setDifficultyString("advanced")
            setSrc(CalimathLogoAdvanced)
            return
        }
        {
            setDifficultyString("expert")
            setSrc(CalimathLogoExpert)
            return
        }
    }, [data])
    useEffect(() => {
        document.title = "Calimath - Thumbnail"
    }, [])
    return (
        <div className="thumbnail-background">
            <div ref={exportRef} className={"thumbnail-div" + (darkmode ? "-dark" : "") + " background-" + branchString}>
                <div className="thumbnail-content">
                    <div className={"thumbnail-title" + (thumbnailWhite ? "-white" : "")}>
                        {data.thumbnailTitle === undefined ? data.title : data.thumbnailTitle}
                    </div>
                    <div className="thumbnail-problem">
                        {data.thumbnailStatement === undefined ? data.problemStatement : data.thumbnailStatement}
                    </div>
                    <div className={"thumbnail-difficulty-background" + (darkmode ? "-dark" : "") + " halftone"} />

                    {(showDifficulty ?
                        <div className="thumbnail-difficulty-inner">
                            <img src={src} alt="Calimath logo" className="thumbnail-difficulty-logo" />
                            <div className={"thumbnail-difficulty-text " + difficultyString.replaceAll(/\s+/g, '-')}>
                                {difficultyString}
                            </div>
                        </div>
                        :
                        <div className="thumbnail-difficulty-inner">
                            <img src={src} alt="Calimath logo" className="thumbnail-difficulty-logo" />
                            <div className={"thumbnail-difficulty-text " + difficultyString.replaceAll(/\s+/g, '-')}>
                                {difficultyString}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}

export default Thumbnail