import {AiOutlineClose} from "react-icons/ai"
import React, {useEffect, useState} from 'react'
import ProblemTag from '../ProblemTag/ProblemTag'
import "../ProblemTag/ProblemTag.css"
import MyTooltip from "../MyTooltip/MyTooltip"


import { alphabeticalTagCompare } from '../../functions/tagCompare'

import "./TagField.css"
import "../../global.css"

export function TagField({values, clearValues, stationary, getOnClick, pluralName}) {

  const [displayValues, setDisplayValues] = useState([])

  useEffect(() => {
    let t = values
    setDisplayValues(t.sort(alphabeticalTagCompare))
  }, [values])

  return (
    <div onMouseDown={(e)=>{
        if (stationary) return 0
        else e.preventDefault()
      }}
      className={stationary ? "my-field down" : "my-field up"}>
      {
        values.length===0 ? (
          stationary ? 
          <div className="field-div">
            All {pluralName} selected
          </div> :
          <></>
        ) : (
        displayValues.map((content, index) => {
          return (
            <ProblemTag key={index} className="small-badge" bg={"primary"} content={content} problemSearch={!stationary} inMenu={true} problemDelete={stationary} getOnClick={getOnClick}/>
          )
        })
        )
      }
      {
        stationary && values.length > 0 ? (
          <div onClick={clearValues} className="cross">
            <MyTooltip placement="top" arrow={false} slow={true} text="Clear selection">
              <div className="primary-text cross-text">
                <AiOutlineClose/>
              </div>
            </MyTooltip>
          </div>
        ) : (
          <></>
        )
      }
    </div>
  )
}

export function RangeField({loValue, hiValue, minValue, maxValue, clearValues, pluralName}) {
  return (
    <div className={"my-field down"}>
      {
        loValue === minValue && hiValue === maxValue ? (
          <div className="field-div">
            All {pluralName} selected
          </div>
        ) : (
          <>
            <div className="field-div">
              [{loValue} + &#949; , {hiValue}]
            </div>
            <div onClick={clearValues} className="cross">
              <MyTooltip placement="top" arrow={false} slow={true} text="Clear Selection">
                <div className="primary-text cross-text">
                  <AiOutlineClose/>
                </div>
              </MyTooltip>
            </div>
          </>
        )
      }
    </div>
  )
}