import React from 'react'

import {FaExternalLinkAlt} from "react-icons/fa"

import MyTooltip from '../MyTooltip/MyTooltip';

import "./ProblemTag.css"
import "../../global.css"
import "../../main.css"

function ProblemTag({content, problemSearch, inMenu, problemDelete, getOnClick}) {
  const contestText = {
    title: <>Contest: </>,
    text1: <>Problems from the </>,
    text2: <> are given this tag.</>
  }
  const yearText = {
    title: <>Year: </>,
    text1: <>Problems from contests from the year </>,
    text2: <> are given this tag.</>
  }
  const branchText = {
    title: <>Branch: </>,
    text1: <>Problems from the mathematical branch of </>,
    text2: <> are given this tag.</>
  }
  const difficultyText = {
    title: <>Difficulty: </>,
    text1: <>Problems of difficulty </>,
    text2: <>, where 6 is equivalent to an easy IMO Shortlist problem and 15 is equivalent to a hard IMO Shortlist problem, are given this tag.</>
  }
  const infoText = {
    title: <>Tag: </>,
    text1: <>Problems related to "</>,
    text2: <>" are given this tag.</>
  }

  const tooltipDiv = (content) => {
    let text;
    if (content.dataClass==="Competition") text = contestText
    else if (content.dataClass==="Year") text = yearText
    else if (content.dataClass==="Branch") text = branchText
    else if (content.dataClass==="Difficulty") text = difficultyText
    else if (content.dataClass==="Info") text = infoText
    else text = contestText

    let valueText
    if (content.url === undefined) {
      valueText = <>{content.title}</>
    }
    else {
      valueText = <a href={content.url} className="secondary-text">{content.title} <FaExternalLinkAlt size = {10}/></a>
    }

    return (
      <div>
        <h4 color="inherit">{text.title} <>{content.title}</></h4>
        {text.text1}{valueText}{text.text2}
        <br/>
        <div className="hint">
          {problemSearch ? "Click tag to add to search." : ""}
          {problemDelete ? "Click tag to delete from search." : ""}
        </div>
      </div>
    )
  }
  
  return (
    <MyTooltip text={tooltipDiv(content)} placement={(content.dataClass==="Contest"||content.dataClass==="Year") ? "top" : "bottom"} arrow={true} slow={false}>
      <div
      onTouchStart={e=>{
        if (!inMenu) e.preventDefault()
      }} onClick={(e)=>{
        getOnClick(content.dataClass, content.tag)
        e.preventDefault()
        if (inMenu) {
          document.activeElement.value=""
          document.activeElement.blur()
        }
      }} bg="primary"
      className="problem-tag"
      >
        {content.tag}
      </div>
    </MyTooltip>
  )
}


export default ProblemTag