import React from 'react'
import "./Footer.css"
import { AiFillLock } from "react-icons/ai"

import { FaExternalLinkAlt } from "react-icons/fa"

function Footer({ admin, adminBox, setAdminBox }) {
    const toggleAdminBox = () => setAdminBox(!adminBox)
    const year = new Date().getFullYear()
    return (
        <footer className="footer">
            <div className="contact-text">
                Feel free to contact us via <a href="mailto:contact@calimath.org" className="primary-text">contact@calimath.org</a>.
            </div>
            <div className="license-text">
                <span onClick={() => toggleAdminBox()}>&copy;</span> 2022 - {year} made by <a className="primary-text" href="https://www.youtube.com/channel/UC-DagxlU75SXG0FntGxKvTA">Calimath <FaExternalLinkAlt size="10" /></a>{" "}for making Olympiad Mathematics more accessible.
            </div>
        </footer>
    )
}

export default Footer