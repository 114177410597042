import React, {useEffect, useState} from 'react'

import MyTooltip from '../MyTooltip/MyTooltip'

import { navigateToWikiPage } from '../../functions/navigateToWikiPage'

import "./WikiInfo.css"
import "../../global.css"
import { useLocation, useNavigate } from 'react-router-dom'

function WikiInfo({children, data, depth}) {
  const location = useLocation()
  const navigate = useNavigate()

  const page = data(1)
  const text = <>
    <h4 color="inherit">{page.title}</h4>
    {page.statement.content}
  </>

  return (
    <>
      {typeof page === "undefined" || depth !== 0 ?
        <>
          {children}
        </>
      :
        <MyTooltip text={text} placement="top" key={"info-" + page.title}>
          <span className="primary-text wiki-info" onClick={() => {
            navigateToWikiPage(page.title, location, navigate)
          }}>
            {children}
          </span>
        </MyTooltip>
      }
    </>
  )
}


export default WikiInfo