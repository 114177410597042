import React from 'react'


import "./WikiInner.css"
import "../../global.css"
import { Inline } from '../MyMath/MyMath'

function WikiInner({ page }) {
    return <>
        {page.statement === undefined ? <></> :
            <div className="foreground-div" >
                {page.statement === undefined ? <></> : page.statement.content}
                {page.proofs === undefined ? <></> : page.proofs.map((proof, index) => {
                    return <div key={index}>
                        <div className="proof-title">
                            Proof. {proof.subtitle === undefined ? "" : <>({proof.subtitle})</>}
                        </div>
                        <div className="proof-div">
                            {proof.content}
                            <div className="proof-qed">
                                <Inline tex="\square" />
                            </div>
                        </div>
                    </div>
                })
                }
                {
                    page.definitions === undefined ? <></> : page.definitions.map((definition, index) => {
                        return <div key={index}>
                            <div className="proof-title">
                                Definition {index + 1}. {definition.subtitle != undefined ? <>({definition.subtitle})</> : ""}
                            </div>
                            {definition.content}
                        </div>
                    })
                }
                {page.notes === undefined ? <></> : page.notes.map((note, index) => {
                    return <div key={index}>
                        <div className="proof-title">
                            Note {index + 1}. {note.subtitle != undefined ? <>({note.subtitle})</> : ""}
                        </div>
                        {note.content}
                        {note.proof == undefined ? "" :
                            <>
                                <div className="proof-title">
                                    Proof.
                                </div>
                                <div className="proof-div">
                                    {note.proof}
                                    <div className="proof-qed">
                                        <Inline tex="\square" />
                                    </div>
                                </div>
                            </>
                        }
                        {note.proofsketch == undefined ? "" :
                            <>
                                <div className="proof-title">
                                    Proof sketch.
                                </div>
                                <div className="proof-div">
                                    {note.proofsketch}
                                    <div className="proof-qed">
                                        <Inline tex="\square" />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                })
                }
                {page.demonstrations === undefined ? <></> : page.demonstrations === undefined ? <></> : page.demonstrations.map((demonstration, index) => {
                    return <div key={index}>
                        <div className="proof-title">
                            Demonstration {index + 1}. {demonstration.subtitle != undefined ? <>({demonstration.subtitle})</> : ""}
                        </div>
                        {demonstration.content}
                    </div>
                })
                }
                {page.examples === undefined ? <></> : page.examples.map((example, index) => {
                    return <div key={index}>
                        <div className="proof-title">
                            Example {index + 1}. {example.subtitle != undefined ? <>({example.subtitle})</> : ""}
                        </div>
                        {example.content}
                        {example.proof == undefined ? "" :
                            <>
                                <div className="proof-title">
                                    Proof.
                                </div>
                                <div className="proof-div">
                                    {example.proof}
                                    <div className="proof-qed">
                                        <Inline tex="\square" />
                                    </div>
                                </div>
                            </>
                        }
                        {example.proofsketch == undefined ? "" :
                            <>
                                <div className="proof-title">
                                    Proof sketch.
                                </div>
                                <div className="proof-div">
                                    {example.proofsketch}
                                    <div className="proof-qed">
                                        <Inline tex="\square" />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                })
                }
            </div>
        }
    </>
}

export default WikiInner