import React, {useRef, useState} from 'react'
import { Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap';
import emailjs from "@emailjs/browser"

import "./ContactForm.css"
import Feedback from 'react-bootstrap/esm/Feedback';
 
function ContactForm () {
  const [firstNameValid, setFirstNameValid] = useState(undefined)
  const [lastNameValid, setLastNameValid] = useState(undefined)
  const [emailValid, setEmailValid] = useState(undefined)
  const [messageValid, setMessageValid] = useState(undefined)

  const [firstNameNone, setFirstNameNone] = useState(undefined)
  const [lastNameNone, setLastNameNone] = useState(undefined)
  const [emailNone, setEmailNone] = useState(undefined)
  const [messageNone, setMessageNone] = useState(undefined)

  const updateFirstNameValid = text => {
    setFirstNameValid(text !== "")
    setFirstNameNone(text === "")
  }

  const updateLastNameValid = text => {
    setLastNameValid(text !== "")
    setLastNameNone(text === "")
  }

  const updateEmailValid = text => {
    setEmailValid(validateEmail(text))
    setEmailNone(text==="")
  }

  const updateMessageValid = text => {
      setMessageValid(text !== "")
      setMessageNone(text==="")
  }

  const resetContactValues = () => {
    setFirstNameValid(undefined)
    setLastNameValid(undefined)
    setEmailValid(undefined)
    setMessageValid(undefined)
    setFirstNameNone(undefined)
    setLastNameNone(undefined)
    setEmailNone(undefined)
    setMessageNone(undefined)
  }

  const validateEmail = email => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const onSubmit = (e) => {
    e.preventDefault()

    let templateParams = {
      from_first_name: e.target.firstName.value,
      from_last_name: e.target.lastName.value,
      reply_to: e.target.email.value,
      message: e.target.message.value,
    }

    updateFirstNameValid(e.target.firstName.value)
    updateLastNameValid(e.target.lastName.value)
    updateEmailValid(e.target.email.value)
    updateMessageValid(e.target.message.value)
    if (e.target.firstName.value === "") alert("Please enter your first name.")
    else if (e.target.lastName.value === "") alert("Please enter your last name.")
    else if (!validateEmail(e.target.email.value)) alert("Please enter your email.")
    else if (e.target.message.value === "") alert("Please enter your message.")
    else {
      emailjs.send("service_1x85zkx", "template_k61j4ot", templateParams, "EOdp0lyDY-1k_FNpu")
      .then(function(response) {
        alert("Success! We received your message and will respond shortly to your provided email adress.")
        e.target.reset()
        resetContactValues()
      }, function(error) {
          console.log('Failed to send message!');
      })
    }
    
  }

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label>
          First name <span className={"star" + (firstNameNone ? " red" : "")}>*</span>
        </Label>
        <Input id="firstName" onBlur={e => updateFirstNameValid(e.target.value)} valid={firstNameValid} invalid={firstNameValid === false}/>
        <FormFeedback>Please enter your first name.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>
            Last name <span className={"star" + (lastNameNone ? " red" : "")}>*</span>
          </Label>
          <Input id="lastName" onBlur={e => updateLastNameValid(e.target.value)} valid={lastNameValid} invalid={lastNameValid === false}/>
          <FormFeedback>Please enter your last name.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>
            Email <span className={"star" + (emailNone ? " red" : "")}>*</span>
          </Label>
          <Input type="email" id="email"  onBlur={e => updateEmailValid(e.target.value)} valid={emailValid} invalid={emailValid === false}/>
          <FormFeedback>Please enter a valid email adress.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>
            Message <span className={"star" + (messageNone ? " red" : "")}>*</span>
          </Label>
          <Input type="textarea" id="message" className="message-input" onBlur={e => updateMessageValid(e.target.value)} valid={messageValid} invalid={messageValid === false}/>
          <FormFeedback>Please enter a message.</FormFeedback>
        </FormGroup>
        *required field
        <div className="submit-button-div">
          <Button type="submit" className="submit-button">
            Submit
          </Button>
        </div>
      </Form>
    )
}

export default ContactForm


