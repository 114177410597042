import React from 'react';

import {Inline, Block} from "../elements/MyMath/MyMath"

export const DefinitionData = {
  AUTHOR: {
    title: "Author",
    explanation: <>
      We write the solution pdfs for our videos, respectively. Therefore, the author is an element of <Inline tex="\{ \mathrm{Timo}, \mathrm{Richard} \}" />.
    </>
  },
  RELEASE_TIME: {
    title: "Release time",
    explanation: <>
      The release time is the local time of the release of the solution on calimath.org, which is equivalent to the release time of the respective Youtube video. <br/>
      We use the dd.mm.yyyy format and military time.
    </>
  },
  DIFFICULTY: {
    title: "Difficulty",
    explanation: <>
      We give every problem a subjective difficulty rating. That is a number <Inline tex="d" />, such that <Inline tex="1 \leq d \leq 20" />. <Inline tex="d=6" /> should be equivalent to the difficulty of an easy problem from a current IMO Shortlist, <Inline tex="d=15" /> should be equivalent to the difficulty of a hard problem from a current IMO Shortlist and the rest of the scale is interpolated from that.
    </>
  },
  CONTEST_YEAR: {
    title: "Contest year",
    explanation: <>
      The contest year is the year of the contest that stated this problem.
    </>
  },
  CONTEST: {
    title: "Contest",
    explanation: <>
      The contest is the name of the contest this problem was stated in.
    </>
  },
  BRANCH: {
    title: "Branch",
    explanation: <>
      The branch is the branch of olympiad mathematics, to which the problem belongs. Therefore, the branch is an element of <Block tex="\{ \textnormal{Algebra}, \textnormal{Combinatorics}, \textnormal{Geometry}, \textnormal{Number Theory} \}." />
    </>
  }
}