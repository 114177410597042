import React, {useState, useEffect, useRef} from 'react'

import {Container, Row, Col} from "react-bootstrap"
import { ScrollToTopOnMount } from '../../functions/ScrollToTop'
import { toPath } from '../../functions/toPath'

import "./SkillPageMenu.css"
import "../../global.css"

import {SkillPageMenuData} from '../../data/SkillPageMenuData'
import SkillPageButton from '../../elements/MyButton/SkillPageButton'
import { useLocation, useNavigate } from 'react-router-dom'

function SkillPageMenu({ admin }) { 
  const location = useLocation()
  const navigate = useNavigate()

  const navigateToPage = (page) => {
    const title = page.title
    navigate("/skillpages/" + toPath(title))
  }

  useEffect(() => {
    document.title = "Calimath - Skillpage Menu"
  }, [])

  return (
    <Container fluid>
      <ScrollToTopOnMount/>
      <Row>
        <Col xs="12">
          <div className="content-box">
            <Row>
              <h1 className="problems-title-col">
                Skillpages
              </h1>
            </Row>
            <Row id="skillpages-row">
              {
                Object.keys(SkillPageMenuData).map(key => SkillPageMenuData[key]).filter(page => {
                  return page.public || admin
                }).map(page =>
                  <Col key={page.id} xs="12">
                    <SkillPageButton data={page} navigateToPage={navigateToPage} />
                  </Col>)
              }
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}


export default SkillPageMenu