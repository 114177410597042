import React from 'react'

import { Bar } from 'react-chartjs-2'
import {Link} from "react-router-dom"

import { publicTime } from "../../functions/myTime"
import { BranchData } from '../../data/TagData'
import PdfData from '../../data/PdfData'

import Chart from 'chart.js/auto';
import "../../global.css"


function DataChart({customDate, date, Icon, title, data, target, pdfs}) {

  const calcCount = (branch) => {
    if (pdfs) {
      return Object.keys(data).map(key => {
        return data[key]()
      }).filter(element => {
        return publicTime(customDate, date, element.releaseTime)
      }).reduce((count, element) => count + (element.branch===branch), 0)
    } else {
      return Object.keys(data).map(key => {
        return data[key]()
      }).filter(element => {
        return element.title !== "Wiki Homepage"
      }).reduce((count, element) => count + (element.branch===branch), 0)
    }
  }

  const branches = Object.keys(BranchData).map((key) => BranchData[key])
  const values = branches.map(branch => calcCount(branch, customDate, date))

  const sum = values.reduce((sum, value) => sum + value, 0)

  const chartData = (pdfs) => {
    return {
      labels: branches.map(branch => branch.title),
      datasets: [{
        label: (pdfs ? "Number of problems and solutions" : "Number of Wiki entries"),
        data: values,
        backgroundColor: "#bb86fc33",
        borderColor: "#bb86fc",
        borderWidth: 2,
        borderRadius: 5
      }]
    }
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
        backgroundColor: '#f5f5f9ee',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: 12,
        titleColor: "#000000",
        bodyColor: "#000000",
        border: "none",
        boxShadow: '5px 5px 8px rgba(0, 0, 0, 0.2)',
      },
    },
    responsive: true,
    scales: {
      x: {
          stacked: true,
          ticks: {
            color: "#ffffff"
          }
      },
      y: {
          stacked: true,
          ticks: {
            color: "#ffffff",
          }
      }
    }
  }

  return (
    <div className="chart-box">
      <div className="content-box-title">
        {title}
      </div>
      <div className="chart-stats">
        <Link to={target} className="primary-text">
          <Icon /> {sum}
        </Link>
      </div>
      <div className="chart">
        <Bar data={chartData(pdfs)} options={options}/>
      </div>
    </div>
  )
}

export default DataChart