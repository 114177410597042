import React, {useState} from 'react'
import "./Switch.css"



function Switch ({active, toggle}) {
  return (
    <div onClick={toggle} className={active ? "switch-element switch-active" : "switch-element inactive"}>
      <div className={active ? "right" : "left"}/>
    </div>
  )
}

export default Switch