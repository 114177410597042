/* global $ */

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { VscFilePdf } from "react-icons/vsc"
import { MdExpandMore, MdExpandLess, MdOutlineCategory } from "react-icons/md"
import { BsPersonFill, BsClockFill, BsThermometerHalf, BsCalendar2Fill, BsFlagFill, BsFillShareFill } from "react-icons/bs"

import { Container, Row, Col } from "react-bootstrap"

import WikiButton from './WikiButton'
import CppButton from './CppButton'
import YoutubeButton from './YoutubeButton'
import ProblemTag from '../ProblemTag/ProblemTag'
import { formatTime } from "../../functions/myTime"
import MyTooltip from '../MyTooltip/MyTooltip'
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard"
import { toPath } from "../../functions/toPath"

import "./MyButton.css"
import "../../global.css"
import "../../main.css"
import { getUniqueId } from '../../functions/getUniqueId'
import { ProblemButtonData } from '../../data/TagData'
import { FaMedal } from 'react-icons/fa'



function PdfButton({ admin, pdfRefs, showMoreId, updateShowMoreId, file, getOnClick, problemSearch, problemDelete, skill }) {
    const showMoreTable = [
        {
            icon: <Link to="/definition/author" className="icon-box">
                <BsPersonFill className="clickable-icon" />
            </Link>,
            iconTooltip: <div>
                Solution and video author
            </div>,
            text: <>
                {file.author}
            </>
        },
        {
            icon: <Link to="/definition/release-time" className="icon-box">
                <BsClockFill className="clickable-icon" />
            </Link>,
            iconTooltip: <div>
                Release time
            </div>,
            text: <>
                {formatTime(file.releaseTime)}
            </>
        },
        {
            icon: <Link to="/definition/difficulty" className="icon-box">
                <BsThermometerHalf className="clickable-icon" />
            </Link>,
            iconTooltip: <div>
                Difficulty
            </div>,
            text: <>
                {file.difficulty.title}
            </>
        },
        {
            icon: <Link to="/definition/contest-year" className="icon-box">
                <BsCalendar2Fill className="clickable-icon" />
            </Link>,
            iconTooltip: <div>
                Contest year
            </div>,
            text: <>
                {file.year.title}
            </>
        },
        {
            icon: <Link to="/definition/contest" className="icon-box">
                <BsFlagFill className="clickable-icon" />
            </Link>,
            iconTooltip: <div>
                Contest
            </div>,
            text: <>
                {file.contest.title
                }</>
        },
        {
            icon: <Link to="/definition/branch" className="icon-box">
                <MdOutlineCategory className="clickable-icon" />
            </Link>,
            iconTooltip: <div>
                Branch
            </div>,
            text: <>
                {file.branch.title
                }</>
        },
    ]

    return (
        <div className="button" ref={e => pdfRefs.current[getUniqueId(file)] = e}>
            <Container fluid className="button-container">
                <div className={"button-row" + (skill ? " skill" : "")} >
                    <div className='main-col'>
                        {(file.buttonType == ProblemButtonData.NO_BUTTON) ? <></> :
                            (file.buttonType == ProblemButtonData.PDF_BUTTON) ?
                                <Link to={process.env.PUBLIC_URL + "/pdf/" + file.fileName} target="_blank" className="icon-box">
                                    <MyTooltip text={<div>Solution pdf</div>} placement={"top"} key="tooltip-solution">
                                        <div>
                                            <VscFilePdf size={skill ? 40 : 70} className="clickable-icon" />
                                        </div>
                                    </MyTooltip>
                                </Link>
                                :
                                <Link to={process.env.PUBLIC_URL + "/skillpages/" + file.fileName} target="_blank" className="icon-box">
                                    <MyTooltip text={<div>Skillpage</div>} placement={"top"} key="tooltip-solution">
                                        <div>
                                            <FaMedal size={skill ? 40 : 70} className="clickable-icon" />
                                        </div>
                                    </MyTooltip>
                                </Link>
                        }
                        <div onClick={() => updateShowMoreId(getUniqueId(file))}>
                            {skill ?
                                <div id="skill-title">
                                    {file.title}
                                    {showMoreId === getUniqueId(file) ?
                                        <MyTooltip text={<div>Show less</div>} placement={"top"} key="tooltip-less">
                                            <span className="icon-box">
                                                <MdExpandLess size="40" className="clickable-icon expand-icon" />
                                            </span>
                                        </MyTooltip>
                                        :
                                        <MyTooltip text={<div>Show more</div>} placement={"top"} key="tooltip-more">
                                            <span className="icon-box">
                                                <MdExpandMore size="40" className="clickable-icon expand-icon" />
                                            </span>
                                        </MyTooltip>
                                    }
                                </div>
                                :
                                <div className="title-box">
                                    {file.title}
                                    {showMoreId === getUniqueId(file) ?
                                        <MyTooltip text={<div>Show less</div>} placement={"top"} key="tooltip-less">
                                            <span className="icon-box">
                                                <MdExpandLess size="40" className="clickable-icon expand-icon" />
                                            </span>
                                        </MyTooltip>
                                        :
                                        <MyTooltip text={<div>Show more</div>} placement={"top"} key="tooltip-more">
                                            <span className="icon-box">
                                                <MdExpandMore size="40" className="clickable-icon expand-icon" />
                                            </span>
                                        </MyTooltip>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {skill ? <></> :
                        <div className="tag-col">
                            <div className="badge-col">
                                <ProblemTag className="small-badge" content={file.contest} problemSearch={problemSearch} inMenu={false} problemDelete={problemDelete} getOnClick={getOnClick} />
                                <ProblemTag className="small-badge" content={file.year} problemSearch={problemSearch} inMenu={false} problemDelete={problemDelete} getOnClick={getOnClick} />
                            </div>
                            <div className="badge-col">
                                <ProblemTag className="small-badge" content={file.branch} problemSearch={problemSearch} inMenu={false} problemDelete={problemDelete} getOnClick={getOnClick} />
                                <ProblemTag className="small-badge" content={file.difficulty} problemSearch={problemSearch} inMenu={false} problemDelete={problemDelete} getOnClick={getOnClick} />
                            </div>
                        </div>
                    }
                </div>
                {showMoreId !== getUniqueId(file) ? <></> :
                    <div className="dropdown-box">
                        {file.problemStatement === undefined ? <></> :
                            <div className="statement">
                                <div className="statement-header">
                                    <h2 className="statement-title">
                                        {file.title}
                                    </h2>
                                    <div id="pdf-share">
                                        <CopyToClipboard text={"https://calimath.org/problems-solutions/" + toPath(file.title)} />
                                    </div>
                                    {admin && file.youtubeVideos.length !== 0 && <div id="aops-share">
                                        <CopyToClipboard text={"We uploaded our solution [url]https://calimath.org/pdf/" + file.fileName + "[/url] on youtube [url]" + file.youtubeVideos[0].url + "[/url]."} />
                                    </div>}
                                </div>
                                <div className="statement-text">
                                    {file.problemStatement}
                                </div>
                            </div>
                        }
                        {
                            file.youtubeVideos.map((video, index) => {
                                return <div key={index} className="extra-button">
                                    <YoutubeButton youtubeVideo={video} />
                                </div>
                            })
                        }
                        {skill ? <></> :
                            <div className='table'>
                                {showMoreTable.map((entry, index) => {
                                    return <Row key={index} className="property-table-row">
                                        <div className="table-text">
                                            <MyTooltip placement="top" key="tooltip-solution" text={entry.iconTooltip}>
                                                <div className="table-icon-div">
                                                    {entry.icon}
                                                </div>
                                            </MyTooltip>
                                            {entry.text}
                                        </div>
                                    </Row>
                                })}
                            </div>
                        }
                        {
                            file.wikiCitations.map((wikiArticle, index) => {
                                return <div key={index} className="extra-button">
                                    <WikiButton article={wikiArticle()} />
                                </div>
                            })
                        }
                        {
                            file.problemCode.map((file, index) => {
                                return <div key={index} className="extra-button">
                                    <CppButton file={file} />
                                </div>
                            })
                        }
                        {file.infoTags.length === 0 ? <></> :
                            <div id="info-tag-field">
                                Tags:
                                {file.infoTags.map((info, index) => {
                                    return <ProblemTag key={index} className="small-badge" content={info} problemSearch={problemSearch} inMenu={false} problemDelete={problemDelete} getOnClick={getOnClick} />
                                })}
                            </div>
                        }
                    </div>
                }
            </Container>
        </div>
    )
}

export default PdfButton