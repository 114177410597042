import React, {useEffect, useState, useRef} from 'react'

import "./Endcard.css"

import exportAsImage from '../../functions/exportAsImage'

function Endcard({ branch }) {
  const exportRef = useRef();
  const [endcardBranch, setEndcardBranch] = useState("")

  const ourVideoBranch = () => {
    if (branch.tag === "A") {
      return "algebra"
    }
    if (branch.tag === "C") {
      return "combinatorics"
    }
    if (branch.tag === "G") {
      return "geometry"
    }
    if (branch.tag === "N") {
      return "number theory"
    }
  }

  useEffect(() => {
    setEndcardBranch(ourVideoBranch())
  }, [])

  return (
    <div ref={exportRef} id="endcard-div" onClick={() => {exportAsImage(exportRef.current, "endcard-" + endcardBranch)}}>
      <div id="endcard-title" className="endcard-text">
        <span className="primary-text">Thanks for watching!</span>
      </div>
      <div id="our-reccomendation" className="reccomended-video endcard-box" />
      <div id="youtube-suggestion" className="reccomended-video endcard-box" />
      <div id="subscribe" className="endcard-box" />
      <div id="our-reccomendation-text" className="endcard-text">
        Watch another {endcardBranch} video
      </div>
      <div id="youtube-suggestion-text" className="endcard-text">
        Continue watching
      </div>
      <div id="subscribe-text" className="endcard-text">
        If you want to see more, <span className="primary-text">like</span>, <span className="primary-text">subscribe</span><br /> or <span className="primary-text">suggest</span> an interesting problem!
      </div>
      <div id="endcard-website" className="endcard-text">
        Explore more maths on our website <span id="website-div" className="primary-text">https://calimath.org</span>!
      </div>
    </div>
  )
}

export default Endcard