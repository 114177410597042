/* global $ */

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { MdExpandMore, MdExpandLess, MdOutlineCategory } from "react-icons/md"

import { Container, Row, Col } from "react-bootstrap"

import { formatTime } from "../../functions/myTime"
import MyTooltip from '../MyTooltip/MyTooltip'
import { FaMedal } from "react-icons/fa"

import "./MyButton.css"
import "../../global.css"
import "../../main.css"
import { PdfLink, WikiLink } from '../SpecialText/SpecialText'
import { Comment } from '../SpecialText/SpecialText'

import PdfData from '../../data/PdfData'
import { publicTime } from '../../functions/myTime'
import { getUniqueId } from "../../functions/getUniqueId"
import WikiInner from '../WikiInner/WikiInner'
import { NewFeature } from '../ForegroundStuff/ForegroundStuff'


function SkillButton({ admin, customDate, date, data, showMoreId, updateShowMoreId, infoRefs }) {
    const [pdfs, setPdfs] = useState([])

    useEffect(() => {
        if (data.type === "wikiEntry") {
            setPdfs(Object.keys(PdfData).map(key => {
                return PdfData[key]()
            }).filter(file => {
                return file.wikiCitations.map(wiki => wiki().id).includes(data.id)
            }).filter(file => {
                return publicTime(customDate, date, file.releaseTime)
            }))
        }
    }, [data, customDate, date])

    return (
        <div className="button" id="skill-button" ref={e => infoRefs.current[getUniqueId(data)] = e}>
            <Container fluid className="button-container">
                <Row>
                    <Col xs="12" onClick={() => updateShowMoreId(getUniqueId(data))}>
                        <div className='main-col'>
                            <span id="skill-title">
                                {data.title}
                            </span>
                            {showMoreId === getUniqueId(data) ?
                                <MyTooltip text={<div>Show less</div>} placement={"top"} key="tooltip-less">
                                    <span className="icon-box">
                                        <MdExpandLess size="40" className="clickable-icon expand-icon" />
                                    </span>
                                </MyTooltip>
                                :
                                <MyTooltip text={<div>Show more</div>} placement={"top"} key="tooltip-more">
                                    <span className="icon-box">
                                        <MdExpandMore size="40" className="clickable-icon expand-icon" />
                                    </span>
                                </MyTooltip>
                            }
                        </div>
                    </Col>
                    {showMoreId !== getUniqueId(data) ? <></> :
                        <div className="dropdown-box">
                            <div id="skill-text">
                                {data.type !== "wikiEntry" ? <></> :
                                    <div className="external-comment">
                                        <Comment>
                                            View wiki article: <WikiLink data={data} />.
                                        </Comment>
                                    </div>
                                }
                                <WikiInner page={data} />
                                {pdfs.length === 0 ? <></> :
                                    <div className="external-comment">
                                        <Comment>
                                            Cited in the following solutions: {" "}
                                            {
                                                pdfs.map((file, index) => {
                                                    const suffix = (index === pdfs.length - 1 ? "" : ", ")
                                                    return (<span key={index}>
                                                        <PdfLink data={file} activated={true} />{suffix}
                                                    </span>)
                                                })
                                            }
                                        </Comment>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </Row>
            </Container>
        </div>
    )
}

export default SkillButton