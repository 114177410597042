import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Navbar from './components/Navbar/Navbar'
import Footer from "./components/Footer/Footer"
import Home from "./pages/Home/Home"
import Problems from "./pages/Problems/Problems"
import Wiki from "./pages/Wiki/Wiki"
import Definition from './pages/Definition/Definition'
import Legal from './pages/Legal/Legal'
import About from './pages/About/About'
import SkillPageMenu from './pages/SkillPageMenu/SkillPageMenu'
import SkillPage from './pages/SkillPage/SkillPage'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'

import "./App.css"
import "./global.css"
import { toDate, serverTime } from "./functions/myTime"
import useCookie from './functions/useCookie'


function App() {
    const [navbarActive, setNavbarActive] = useState(false)
    const [admin, setAdmin] = useCookie("admin", false)
    const [adminBox, setAdminBox] = useState(false)


    const tommorow = new Date(serverTime() + 1000 * 60 * 60 * 24 * 7)
    const [year, setYear] = useCookie("year", tommorow.getUTCFullYear())
    const [month, setMonth] = useCookie("month", tommorow.getUTCMonth() + 1)
    const [day, setDay] = useCookie("day", tommorow.getUTCDate())
    const [hour, setHour] = useCookie("hour", tommorow.getUTCHours())
    const [minute, setMinute] = useCookie("minute", tommorow.getUTCMinutes())
    const [date, setDate] = useState(toDate(year, month, day, hour, minute))
    const setDateOf = (year, month, day, hour, minute) => {
        setDate(toDate(year, month, day, hour, minute))
    }
    const [customDate, setCustomDate] = useCookie("customDate", false)
    const toggleCustomDate = () => {
        setCustomDate(!customDate, 12)
        setDateOf(year, month, day, hour, minute)
    }

    const [fullWiki, setFullWiki] = useCookie("fullWiki", false)
    const toggleFullWiki = () => {
        setFullWiki(!fullWiki)
    }

    const [showThumbnail, setShowThumbnail] = useState(false)
    const toggleShowThumbnail = () => {
        setShowThumbnail(!showThumbnail)
    }

    const [showChannelDetails, setShowChannelDetails] = useState(false)
    const toggleShowChannelDetails = () => {
        setShowChannelDetails(!showChannelDetails)
    }

    const navbarProps = {
        navbarActive: navbarActive,
        setNavbarActive: setNavbarActive,
        admin: admin,
        setAdmin: setAdmin,
        adminBox: adminBox,
        setAdminBox: setAdminBox,
        customDate: customDate,
        toggleCustomDate: toggleCustomDate,
        year: year,
        setYear: setYear,
        month: month,
        setMonth: setMonth,
        day: day,
        setDay: setDay,
        hour: hour,
        setHour: setHour,
        minute: minute,
        setMinute: setMinute,
        setDateOf: setDateOf,
        fullWiki: fullWiki,
        toggleFullWiki: toggleFullWiki,
        showThumbnail: showThumbnail,
        toggleShowThumbnail: toggleShowThumbnail,
        showChannelDetails: showChannelDetails,
        toggleShowChannelDetails: toggleShowChannelDetails,
    }
    return (
        <Router>
            <div onTouchEnd={() => setNavbarActive(false)} onKeyDown={e => {
                if (e.key === "Escape") {
                    setNavbarActive(false)
                }
            }}>
                <Navbar className="navbar" {...navbarProps} />
                <div className="content-page" compr={navbarActive ? "small" : "large"}>
                    <main>
                        <Routes>
                            <Route path="/" element={<Home customDate={customDate} date={date} />} />
                            <Route path="/problems-solutions/*" element={<Problems admin={admin} customDate={customDate} date={date} navbarActive={navbarActive} showThumbnail={showThumbnail} showChannelDetails={showChannelDetails} />} />
                            <Route path="/wiki/*" element={<Wiki admin={admin} customDate={customDate} date={date} fullWiki={fullWiki} />} />
                            <Route path="/definition/*" element={<Definition />} />
                            <Route path="/legal-disclosure" element={<Legal />} />
                            <Route path="/about+contact" element={<About />} />
                            <Route path="/skillpages" element={<SkillPageMenu admin={admin} />} />
                            <Route path="/skillpages/*" element={<SkillPage admin={admin} customDate={customDate} date={date} showThumbnail={showThumbnail} />} />

                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </main>
                    <Footer admin={admin} adminBox={adminBox} setAdminBox={setAdminBox} />
                </div>
            </div>
        </Router>
    );
}

export default App;
