import Graph from "react-graph-vis"
import React, { useState } from "react"

const options = {
  layout: {
    hierarchical: false
  },
  edges: {
    arrows: {
      to: {
        enabled: false,
      }
    },
    width: 3,
    color: "#ffffff",
    smooth: {
      enabled: true,
      type: "dynamic",
      roundness: 0.5
    },
  },
}

const MyGraph = () => {
  const color1 = "#00BB9E"
  const color2 = "#FFA87E"

  const [state, setState] = useState({
    counter: 5,
    graph: {
      hierarchical: true,
      nodes: [
        { id: 1, label: "", color: color1 },
        { id: 2, label: "", color: color1 },
        { id: 3, label: "", color: color1 },
        { id: 4, label: "", color: color1 },
        { id: 5, label: "", color: color2 },
        { id: 6, label: "", color: color2 },
        { id: 7, label: "", color: color2 },
        { id: 8, label: "", color: color2 },
        { id: 9, label: "", color: color2 },
        { id: 10, label: "", color: color2 },
        { id: 11, label: "", color: color2 },
        { id: 12, label: "", color: color1 },
      ],
      edges: [
        { from: 1, to: 2, length: "100" },
        { from: 2, to: 3, length: "100" },
        { from: 3, to: 1, length: "100" },
        { from: 4, to: 5, length: "100" },
        { from: 5, to: 6, length: "100" },
        { from: 5, to: 7, length: "100" },
        { from: 7, to: 8, length: "100" },
        { from: 8, to: 6, length: "100" },
        { from: 6, to: 9, length: "100" },
        { from: 6, to: 10, length: "100" },
        { from: 7, to: 1, length: "100" },
        { from: 11, to: 1, length: "100" },
        { from: 10, to: 12, length: "100" },
        { from: 9, to: 12, length: "100" },
        { from: 9, to: 10, length: "100" },
        { from: 10, to: 1, length: "100" },
        { from: 9, to: 4, length: "100" },
        { from: 2, to: 5, length: "300" },
      ]
    },
  })
  const { graph, events } = state;
  return (
    <div>
      <Graph graph={graph} options={options} events={events} style={{ height: "640px" }} />
    </div>
  );

}

export default MyGraph