import React, {useState, useEffect} from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'

import {Container, Row, Col} from "react-bootstrap"
import {DefinitionData} from '../../data/DefinitionData'

import "./Definition.css"
import "../../global.css"

import { ScrollToTopOnMount } from '../../functions/ScrollToTop'
import { toPath } from "../../functions/toPath"

function Definition() {
  const location = useLocation()
  const navigate = useNavigate()
  const [definition, setDefinition] = useState(undefined)
  useEffect(() => {
    if (Object.keys(DefinitionData).map(key => DefinitionData[key]).every(availableDefinition => {
      if (location.pathname === "/definition/" + toPath(availableDefinition.title)) {
        setDefinition(availableDefinition)
        return false
      }
      else {
        return true
      }
    })) {
      navigate("/")
    }
  }, [])

  useEffect(() => {
    if (definition !== undefined) {
      document.title = "Calimath - " + definition.title + " definition"
    }
  }, [definition])

  return (
    <Container fluid>
      <ScrollToTopOnMount/>
      {definition !== undefined ?
      <div className="content-box">
        <h1>
          {definition.title}
        </h1>
        <div className="foreground-div">
          {definition.explanation}
        </div>
      </div>
      :
      <></>
    }
    </Container>
  )
}

export default Definition