import React from 'react';

import { toPath } from "../../functions/toPath"

import "./SpecialText.css"
import {FaExternalLinkAlt} from "react-icons/fa"

export function Comment({ children }) {
  return <div id="comment-div">
    {children}
  </div>
}

export function PdfLink({ data, activated=false }) {
  const problem = (activated ? data : data())

  return <a className="primary-text" href={"/problems-solutions/" + toPath(problem.title)} target="_blank">
    {problem.title}
  </a>
}

export function WikiLink({ data }) {

  return <a className="primary-text" href={"/wiki/" + toPath(data.title)} target="_blank">
    {data.title}
  </a>
}

export function UrlLink({ data, children, iconSize=10 }) {
  return <a className="primary-text" href={data} target="_blank">
    {children}  <FaExternalLinkAlt size = {iconSize}/>
  </a>
}