import React from 'react';

import { BranchData } from './TagData'
import { Inline, Block } from "../elements/MyMath/MyMath"

import "./data.css"
import { Comment, PdfLink, UrlLink, WikiLink } from '../elements/SpecialText/SpecialText'
import PdfData from './PdfData'
import WikiInfo from '../elements/WikiInfo/WikiInfo'
import WikiData from './WikiData'

let SkillsData = {
    CHECKING_INJECTIVE_SURJECTIVE: function (depth = 0) {
        return {
            id: 0,
            type: "skillEntry",
            title: "Checking for injectivity and surjectivity",
            statement: {
                content: <>
                    We prove injectivity or surjectivity by checking the definitions directly. <br />
                    To prove that a function <Inline tex="f : X \to Y" /> is injective, we assume that <Inline tex="f(x_1) = f(x_2)" /> and want to show that this implies <Inline tex="x_1 = x_2" />. <br />
                    To prove that a function <Inline tex="f : X \to Y" /> is surjective, we take <Inline tex="y \in Y" /> and want to show that there exists <Inline tex="x \in X" /> with <Inline tex="f(x) = y" />.
                </>
            },
            notes: [
                {
                    content: <>
                        Injectivity and surjectivity are also often proven with a proof by contradiction.
                    </>
                }
            ],
            demonstrations: [
                {
                    subtitle: undefined,
                    content: <>
                        Knowing that <Inline tex="f : X \to X" /> is surjective, we can substitute <Inline tex="y := f(x)" />. By surjectivity, <Inline tex="y \in X" /> can be of arbitrary value.</>,
                },
                {
                    subtitle: undefined,
                    content: <>
                        Knowing that <Inline tex="f : X \to X" /> is injective, we can deduce from an equation <Block tex="f(T(x, y)) = f(S(x, y))" /> that <Block tex="T(x, y) = S(x, y)," /> where <Inline tex="T(x, y), S(x, y)" /> are expressions in terms of variables <Inline tex="x, y \in X" />.
                    </>
                }
            ],
            examples: [
                {
                    content: <>
                        The equation <Block tex="f(f(x)) = x \quad \forall x \in X \quad (f : X \to X)" /> appears very often, for example in <PdfLink data={PdfData.IMO_2009_5} />. It implies that <Inline tex="f" /> is bijective. <br />
                        To prove this, we can simply note that <Inline tex="f" /> is its own inverse (We call such functions involutions). More elementary, we can deduce
                        <Block tex="f(x) = f(y) \implies f(f(x)) = f(f(y)) \implies x = y" />
                        and
                        <Block tex="y \in X \implies f(f(y)) = y \implies \exists x (= f(y)) \in X: f(x) = y." />
                    </>
                }
            ],
            branch: BranchData.A,
        }
    },
    PLUGGING_IN_SMALL_VALUES_FE: function (depth = 0) {
        return {
            id: 1,
            type: "skillEntry",
            title: "Plugging in small values",
            statement: {
                subtitle: undefined,
                content: <>
                    This means, we want to plug in concrete numbers for our variables. We say that we want to plug in "small" values because <Inline tex="0" /> or <Inline tex="1" /> are the best choices most of the time. They tend to simplify the equation.
                </>
            },
            notes: [{
                subtitle: undefined,
                content: <>
                    Hence, it is useful to know the values <Inline tex="f(0)" /> or <Inline tex="f(1)" />. This will simplify such equations even more.
                    <Comment>
                        A good demonstration for this strategy is <PdfLink data={PdfData.BALTIC_WAY_2022_5} />. And here, we can clearly see why it becomes useful to know the values <Inline tex="f(0)" /> or <Inline tex="f(1)" />.
                    </Comment>
                </>
            }],
            branch: BranchData.A,
        }
    },
    USING_SYMMETRY: function (depth = 0) {
        return {
            id: 3,
            type: "skillEntry",
            title: "Using symmetry",
            statement: {
                subtitle: undefined,
                content: <>
                    We can plug in <Inline tex="(y, x)" /> for <Inline tex="(x, y)" />. If one side is symmetric in <Inline tex="x" /> and <Inline tex="y" /> to begin with, this implies that the other side must be symmetric as well.
                    <Comment>
                        You can solve <PdfLink data={PdfData.VNO_2022_2} /> and <PdfLink data={PdfData.Estonia_TST_2019_3} /> using this strategy.
                    </Comment>
                </>
            },
            demonstrations: [{
                subtitle: undefined,
                content: <>
                    Consider the functional equation <Block tex="f(xf(y)) = f(x)f(y) - y \quad \forall x, y \in \mathbb R." /> This implies
                    <Block tex="f(xf(y)) + y = f(x)f(y) = f(yf(x)) + x." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    MONOTONY_FE: function (depth = 0) {
        return {
            id: 4,
            type: "skillEntry",
            title: "Monotony and (un)boundedness",
            statement: {
                subtitle: undefined,
                content: <>
                    Knowing that <Inline tex="f" /> is (strictly) monotonic or bounded or unbounded can be very useful.
                </>
            },
            notes: [{
                content: <>
                    A function <Inline tex="f : \mathbb N \to \mathbb N" /> may not be strictly decreasing or unbounded from below.
                </>
            },
            {
                content: <>
                    A strictly increasing function <Inline tex="f : \mathbb N \to \mathbb N" /> is not bounded from above.
                </>
            },
            {
                content: <>
                    Proving that <Inline tex="f" /> attains arbitrary large values is easiest when there is exactly one occurence of a variable outside of <Inline tex="f" /> in our equation. An easy case is of the form <Block tex="f(S(x, y)) + f(T(x, y)) = y + f(Q(x, y)) \quad \forall x, y \in \mathbb R" /> for <Inline tex="S, T, Q" /> arbitrary terms. It implies that <Inline tex="|f|" /> is unbounded.
                    <Comment>
                        It should be noted that unboundedness does not imply surjectivity - not even in this example, where there is an isolated <Inline tex="y" /> term.
                    </Comment>
                </>
            }],
            branch: BranchData.A,
        }
    },
    INVESTIGATING_ROOTS: function (depth = 0) {
        return {
            id: 5,
            type: "skillEntry",
            title: "Investigating roots",
            statement: {
                content: <>
                    Assume, there exists an <Inline tex="\alpha" /> with <Inline tex="f(\alpha) = 0" />. We want to plug this into the equation to simplify it.
                </>
            },
            notes: [
                {
                    content: <>
                        If <Inline tex="f" /> is surjective, we know that such an <Inline tex="\alpha" /> exists.
                    </>
                },
                {
                    content: <>
                        On the other hand, we might get a contradiction or show that <Inline tex="\alpha = 0" /> must hold (which is often the case).
                        <Comment>
                            Due to the "Plugging in small values" step, we are often left with <Inline tex="f(0)" /> and <Inline tex="f(1)" /> terms. Therefore, we would like to know their value, which gives motivation for investigating zeros.
                        </Comment>
                        <Comment>
                            For an example, see <PdfLink data={PdfData.INMO_2015_3} />.
                        </Comment>
                    </>
                },
                {
                    content: <>
                        It can also make sense to do the same thing for <Inline tex="f(\beta) = 1." />
                    </>
                }
            ],
            branch: BranchData.A,
        }
    },
    INDUCTION_FE: function (depth = 0) {
        return {
            id: 6,
            type: "skillEntry",
            title: "Induction",
            statement: {
                content: <>
                    We use induction on the values of function arguments.
                </>
            },
            demonstrations: [{
                subtitle: undefined,
                content: <>
                    From <Block tex="f(n+1) = f(n) + c \quad \forall n \in \mathbb Z," /> we can deduce <Block tex="f(n) = c \cdot n + f(0) \quad \forall n \in \mathbb Z." />
                </>,
            },
            {
                subtitle: <>From <Inline tex="\mathbb Z" /> to <Inline tex="\mathbb Q" /> (to <Inline tex="\mathbb R" />)</>,
                content: <>
                    Let's consider a functional equation on <Inline tex="X \in \{\mathbb Q, \mathbb R\}" />. From <Block tex="f(k \cdot x) = k \cdot f(x) \quad \forall k \in \mathbb Z, x \in X," /> we can deduce <Block tex="b \cdot f\left(\frac{a}{b}\right) = f(a) = a \cdot f(1) \quad \forall a, b \in \mathbb Z, b > 0" /> and thus <Block tex="f(x) = x \cdot f(1) \quad \forall x \in \mathbb Q." /> This is nice for <Inline tex="X = \mathbb Q" /> already. For <Inline tex="X = \mathbb R" /> such an equation can also be useful. For example, if we can prove that <Inline tex="f" /> is monotonic or that <Inline tex="f" /> is continuous, this implies <Block tex="f(x) = x \cdot f(1) \quad \forall x \in \mathbb R." />
                </>,
            },
            ],
            branch: BranchData.A,
        }
    },
    ASSUMING_DIFFERENT_VALUE: function (depth = 0) {
        return {
            id: 7,
            type: "skillEntry",
            title: "Assume the function is at some point not equal (larger / smaller) to the value we expect",
            statement: {
                content: <>
                    If we guess the unique solution <Inline tex="f_0" /> to a functional equation, we may do a proof by contradiction assuming that <Inline tex="\exists x : f(x) \neq f_0(x)" />.
                </>
            },
            notes: [{
                content: <>
                    When working in <Inline tex="\mathbb N" />, we may want to rule out <Inline tex="f(x) < f_0(x)" /> and <Inline tex="f(x) > f_0(x)" /> seperately. The general reason for that is that different integers have absolut value difference at least <Inline tex="1" />. So, we might be able to deduce from <Inline tex="f(x) < f_0(x)" /> that <Inline tex="f(n) < 0" /> for some <Inline tex="n\in\mathbb{N}" />, which gives us a contradiction.
                </>
            },
            {
                content: <>
                    This idea can be modified for when there are multiple solutions. For example, we can do this just for one case for which we want to show that there is exactly one solution.
                </>
            }],
            branch: BranchData.A,
        }
    },
    VALUES_OF_SELECTED_SERIES: function (depth = 0) {
        return {
            id: 8,
            type: "skillEntry",
            title: "Values of selected series",
            statement: {
                subtitle: undefined,
                content: <div>
                    We have
                    <Block tex="\sum_{n = 1}^\infty \frac{1}{n} = \infty \quad \textnormal{and} \quad \sum_{n = 1}^\infty \frac{1}{n^2} = \frac{\pi^2}{6} < 2," />
                    and
                    <Block tex="\sum_{p \, \textnormal{prime}} \frac{1}{p} = \infty \quad \textnormal{and} \quad \sum_{p \, \textnormal{prime}} \frac{1}{p^2} < \frac{1}{2}." />
                </div>,
            },
            proofs: [
                {
                    subtitle: "Harmonic series",
                    content: <>
                        We have
                        <Block tex="\sum_{n = 1}^{2^K} \frac{1}{n} = 1 + \sum_{k = 1}^{K} \sum_{n = 0}^{2^{k-1} - 1} \underbrace{\frac{1}{2^k - n}}_{\geq 1 / 2^k} > \sum_{k = 0}^{K} 2^{k-1} \cdot \frac{1}{2^k} = \sum_{k = 0}^K \frac{1}{2} \xrightarrow{K \to \infty} \infty." />
                    </>
                },
                {
                    subtitle: "Sum of square reciprocals",
                    content: <>
                        We have
                        <Block tex="\sum_{n = 1}^N \frac{1}{n^2} < 1 + \sum_{n = 2}^N \underbrace{\frac{1}{n(n-1)}}_{=1 / (n - 1) - 1 / n} = 2 - 1 / N \xrightarrow{N \to \infty} 2." />
                        For some proofs of the second equality (that is less relevant for olympiad problems), see <UrlLink data="https://en.wikipedia.org/wiki/Basel_problem">Basel problem</UrlLink>.
                    </>
                },
                {
                    subtitle: "Sum of prime reciprocals",
                    content: <>
                        Let <Inline tex="p_i" /> be the <Inline tex="i" />-th prime. Assume that <Inline tex="\sum_{p \, \textnormal{prime}} \frac{1}{p}" /> converges. So, we find an <Inline tex="N \in \mathbb N" /> such that <Block tex="\alpha := \sum_{n > N} \frac{1}{p_n} < 1." /> This gives us for <Inline tex="P := p_1 \cdot \ldots \cdot p_N" /> that
                        <Block tex="\frac{1}{1 - \alpha} = \sum_{k = 0}^\infty \alpha^k = \sum_{\substack{n \geq 1 \\ \mathrm{gcd}(P, n) = 1}} \frac{1}{n}" />
                        converges. Since for all <Inline tex="n" />, we have <Inline tex="\mathrm{gcd}(nP + 1, P) = 1" />. We can use this to get a contradiction to the unboundedness of the harmonic series:
                        <Block tex="\frac{1}{1 - \alpha} \geq \sum_{n = 1}^\infty \frac{1}{nP + 1} \geq \sum_{n = 1}^\infty \frac{1}{P+1} \frac{1}{n}." />
                    </>
                },
                {
                    subtitle: "Sum of prime-square reciprocals",
                    content: <>
                        This fact may be useful to know especially because <Inline tex="1 / 2 < 1" />. The proof goes as follows:
                        <Block tex="\sum_{p \, \textnormal{prime}} \frac{1}{p^2} \leq \sum_{i = 1}^6 \frac{1}{p_i^2} + \sum_{n = p_7}^\infty \frac{1}{n^2} \leq \sum_{i = 1}^6 \frac{1}{p_i^2} + \frac{1}{p_7 - 1} < \frac{1}{2}." />
                    </>
                }
            ],
            branch: BranchData.N,
        }
    },
    CHECK_THE_SOLUTION_FE: function (depth = 0) {
        return {
            id: 9,
            type: "skillEntry",
            title: "Check that your answer is indeed a solution",
            statement: {
                subtitle: undefined,
                content: <div>
                    Do not forget to check that your answer is indeed a solution. This is part of the proof for finding all solutions to a functional equation.
                </div>,
            },
            branch: BranchData.A,
        }
    },
    BEWARE_OF_THE_DOMAIN_FE: function (depth = 0) {
        return {
            id: 10,
            type: "skillEntry",
            title: "Be aware of the domain",
            statement: {
                subtitle: undefined,
                content: <div>
                    Thinking that you have instasolved a functional equation on <Inline tex="\mathbb R^+" /> by plugging in <Inline tex="0" /> doesn't feel good afterwards. So, don't do that. <br />
                    Moreover, we always have to keep track for which values of <Inline tex="x, y" /> our equations hold. Importantly, <Inline tex="x^2" /> is not surjective on <Inline tex="\mathbb R" /> or <Inline tex="\mathbb Q^+" />. Therefore, from <Block tex="f(x^2) = x^2 \quad \forall x \in X," /> we cannot deduce <Block tex="f(x) = x \quad \forall x \in X" /> for <Inline tex="X \in \{\mathbb R, \mathbb Q\}" />.
                </div>,
            },
            notes: [{
                content: <>
                    The equation <Block tex="f(x)^2 = x^2" /> does imply <Block tex="f(x) = x \quad \forall x \in \mathbb Q^+" /> for <Inline tex="f : \mathbb Q^+ \to \mathbb Q^+" />. This is not the case for <Inline tex="f : \mathbb R \to \mathbb R" />, where it leads to a common situation: We get <Block tex="f(x) \in \{-x, x\} \quad \forall x \in \mathbb R." /> This <b>does not</b> imply that <Block tex="f(x) = x \quad \forall x \in \mathbb R \quad \textnormal{and} \quad f(x) = -x \quad \forall x \in \mathbb R" /> are the only possible solutions, though this is often the case.
                    <Comment>
                        An example for how we can deal with this is <PdfLink data={PdfData.RMM_SL_2019_A1} />.
                    </Comment>
                </>
            }],
            branch: BranchData.A,
        }
    },
    MAKING_TERMS_CANCEL: function (depth = 0) {
        return {
            id: 11,
            type: "skillEntry",
            title: "Making terms cancel",
            statement: {
                subtitle: undefined,
                content: <>
                    We want to simplify the functional equation. Therefore, it makes sense to plug in values such that a term becomes <Inline tex="0" /> or two terms cancel out.
                </>
            },
            demonstrations: [{
                subtitle: undefined,
                content: <>From <Block tex="f(x - f(y)) = f(x) - y \quad \forall x, y \in \mathbb Z," /> we can deduce <Block tex="f(f(y)) = y + f(0)" /> by setting <Inline tex="x = f(y)." />
                    <Comment>
                        We encounter the equation <Inline tex="f(f(x)) = x + c" /> frequently [see "Checking for injectivity and surjectivity"].
                    </Comment>
                </>
            },
            {
                subtitle: undefined,
                content: <>From <Block tex="f(xf(y) + 1) + f(f(x) + y + 1) = f(xy) + y \quad \forall x, y \in \mathbb R," /> we can deduce <Block tex="f\left(f\left(\frac{1}{y - f(y)}\right) + y + 1\right) = y \quad \forall y : y \neq f(y)" /> by setting <Inline tex="x = \frac{1}{y - f(y)}." /><br />
                    <Comment>
                        And therefore, we may notice that <Inline tex="f" /> is surjective.
                    </Comment>
                </>
            }],
            branch: BranchData.A,
        }
    },
    GUESSING_ALL_SOLUTIONS: function (depth = 0) {
        return {
            id: 12,
            type: "skillEntry",
            title: "Guessing the solutions",
            statement: {
                subtitle: undefined,
                content: <>
                    Often, we can guess the solution(s) to a functional equation immediately. We can use this knowledge to do a proof by contradiction. Moreover, it helps us to picture the meaning of the equations which we encounter during the solve. In fact, we might want to check if our solution still satisfies all the equations we obtain, if we have to do messy manipulations.
                </>
            },
            notes: [{
                content: <>
                    If you can't guess a solution from the initial functional equation, try to do so using equations you obtain during the solve. Rarely, there is no solution to a functional equation in a competition.
                </>
            }],
            branch: BranchData.A,
        }
    },
    FIELD_EXTENSIONS: function (depth = 0) {
        return {
            id: 13,
            type: "skillEntry",
            title: "Field extensions",
            statement: {
                content: <>
                    The complex numbers are an extension of the real numbers: <Inline tex="\mathbb C = \mathbb R[i]." /> There are more contexts, where extensions are relevant for olympiad mathematics.
                </>
            },
            examples: [{
                subtitle: "Pell's equation",
                content: <>
                    To solve pell's equation
                    <Block tex="x^2 - dy^2 = 1" />
                    for some <WikiInfo depth={depth} data={WikiData.SQUARE_FREE_INTEGER}>square-free</WikiInfo> <Inline tex="d \in \mathbb N," /> we consider <Block tex="\mathbb Z[\sqrt d] = \{a + b\sqrt{d} \mid a, b \in \mathbb Z\}." /> If we define
                    <Block tex="|a + b\sqrt{d}| := (a + b\sqrt{d})\overline{(a + b\sqrt{d})} := (a + b\sqrt{d})(a - b\sqrt{d}) = a^2 - db^2," />
                    then we notice that the positive solutions <Block tex="\mathscr L := \{(x, y) \mid x^2 - dy^2 = 1, x, y > 0\}" /> to pell's equation correspond to <Block tex="a + b\sqrt{d} \in \mathbb Z[\sqrt{d}]" /> with absolute value <Inline tex="1." /> Using the fact that this absolute value function is multiplicative, we can thus generate infinitely many solutions <Inline tex="(x_n, y_n)" /> with one solution <Inline tex="(x_1, y_1) \in \mathscr L" /> and the recurrence
                    <Block tex="x_n + \sqrt{d}y_n := (x_1 + \sqrt{d}y_1)(x_{n-1} + \sqrt{d}y_{n-1})." /><br />
                    Using the fact that <Inline tex="w \in \mathscr L" /> has a multiplicative inverse (Confirm that fact but note that a multiplicative inverse of <Inline tex="w \in \mathbb Z[\sqrt{d}]" /> exists if and only if <Inline tex="|w| \in \{-1, 1\}" />. Thus, we see that <Inline tex="\mathbb Z[\sqrt{d}]" /> is not a field - the same is true for <Inline tex="\mathbb Z." />), we can also see that all solutions are generated in this way if <Inline tex="x_1 + \sqrt{d}y_1" /> is minimal for <Inline tex="(x_1, y_1) \in \mathscr L." />
                </>
            }, {
                subtitle: "Prime field extension",
                content: <>
                    We can extend a <WikiInfo depth={depth} data={WikiData.PRIME_FIELD}>prime field</WikiInfo> <Inline tex="\mathbb F_p" /> in the same way. This is generally useful if we work with a (quadratic) polynomial <Inline tex="\mod p" /> that we would like to factor and that is <WikiInfo depth={depth} data={WikiData.IRREDUCIBLE_POLYNOMIAL}>irreducible</WikiInfo> over <Inline tex="\mathbb F_p." /><br />
                    A good exmaple for this strategy is the following exercise: Try to use a prime field extension to prove the second supplement of <WikiInfo depth={depth} data={WikiData.LAW_OF_QUADRATIC_RECIPROCITY}>quadratic reciprocity</WikiInfo>. For <Inline tex="p \equiv 1 \mod 4," /> the first supplement of quadratic reciprocity tells us <Inline tex="\exists i \in \mathbb F_p: i^2 = -1 \in \mathbb F_p" /> and we finish by using <Block tex="2 = i(1-i)^2." /> In the second, more difficult case you need to work in <Block tex="\mathbb F_p[i] = \{a + bi \mid a, b \in \mathbb F_p\}." />
                </>
            }],
            branch: BranchData.N,
        }
    },
    COLLINEARITY_COMPLEX: function (depth = 0) {
        return {
            id: 14,
            type: "skillEntry",
            title: "Collinearity and perpendicularity",
            statement: {
                content: <>
                    Let <Inline tex="P \not \in \{A, B\}." /> <Inline tex="A, B, P" /> are collinear iff <Block tex="\frac{a-p}{b-p} = \overline{\left(\frac{a-p}{b-p}\right)}." />
                </>
            },
            proofs: [{
                content: <>
                    <Inline tex="A, B, P" /> are collinear <Inline tex="\iff a-p, b-p" /> are parallel
                    <Block tex="\iff \exists \lambda \in \mathbb R : a - p = \lambda (b-p)" />
                    <Block tex="\iff \frac{a-p}{b-p} \in \mathbb R" /> <br />
                    <Block tex="\iff \frac{a-p}{b-p} = \overline{\left(\frac{a-p}{b-p}\right)}." />
                </>
            }],
            notes: [{
                content: <>
                    Analogously, <Inline tex="AP, BP" /> are perpendicular iff <Block tex="\frac{a-p}{b-p} = -\overline{\left(\frac{a-p}{b-p}\right)}." />
                </>
            }]
        }
    },
    GENERAL_COMPLEX_BASH: function (depth = 0) {
        return {
            id: 15,
            type: "skillEntry",
            title: "General approach",
            statement: {
                content: <>
                    Complex bash is a strategy for solving geometry problems.
                    <ol>
                        <li>
                            We consider a complex coordinate system such that every point in the plane is associated with a complex number.
                        </li>
                        <li>
                            We translate the geometric conditions into equations of complex numbers.
                        </li>
                        <li>
                            We use the rules for complex arithmetic to manipulate these equations.
                        </li>
                        <li>
                            We infer an equation that implies the geometric condition that we wish to prove.
                        </li>
                    </ol>
                </>
            },
            notes: [{
                content: <>
                    Conventionally, we denote any point in the plane with a capital letter (e.g. <Inline tex="P" />) and the associated complex number with the same letter in lower case (e.g. <Inline tex="p" />).
                </>
            },
            {
                content: <>
                    Let <Inline tex="A" /> lie on the unit circle with respect to the coordinate system or <Inline tex="|a| = 1" />. We know that <Inline tex="1 = |a|^2 = a \bar a." /> Thus, we have <Inline tex="\bar a = \frac{1}{a}." /> We use this property to compute conjugates of complex expressions. <br />
                    Thus, we like to choose the complex coordinate system such that many important points lie on the unit circle. For example, we often choose the circumcircle of an important triangle as the unit circle.
                </>
            }, {
                content: <>
                    Remembering how to prove the following formulas is likely more useful and easier than remembering the statements.
                </>
            }],
            demonstrations: [{
                content: <>
                    For an example of a complex bash, see <PdfLink data={PdfData.USA_TSTST_2023_6} />.
                </>
            },]
        }
    },
    TRIANGLE_CENTERS: function (depth = 0) {
        return {
            id: 16,
            type: "skillEntry",
            title: "Triangle centers",
            statement: {
                content: <>
                    Let <Inline tex="|a| = |b| = |c| = 1." /> Let <Inline tex="O, J, H, S" /> be circum-, in-, orthocenter, and centroid of <Inline tex="\triangle ABC." /> We have
                    <ul>
                        <li><Inline tex="o = 0," /></li>
                        <li><Inline tex="s = \frac{a + b + c}{3}," /></li>
                        <li><Inline tex="h = a + b + c," /></li>
                        <li><Inline tex="\exists x, y, z" /> on the unit circle with <Inline tex="a = x^2, b = y^2, c = z^2" /> and <Inline tex="j = -(xy + yz + zx)." /> </li>
                    </ul>
                </>
            },
            proofs: [{
                subtitle: <>Formulas for <Inline tex="o, s, h" /></>,
                content: <>
                    The formula for <Inline tex="o" /> is trivial.<br />
                    We confirm that <Inline tex="a, \frac{a+b+c}{3}, \frac{b+c}{2}" /> are collinear and that the two lines defined by <Inline tex="a, a+b+c" /> and <Inline tex="b, c" /> are orthogonal. The symmetric observations must also hold. Thus, the formulas for <Inline tex="s" /> and <Inline tex="h" /> follow by definition.
                </>
            },
            {
                subtitle: <>Formula for <Inline tex="j" /></>,
                content: <>
                    Let <Inline tex="S_A, S_B, S_C" /> be the south-poles of <Inline tex="\triangle ABC" />, i.e. <Inline tex="\{K, S_K\} = KJ \cap \Omega," /> where <Inline tex="\Omega" /> is the circumcircle of <Inline tex="\triangle ABC." /> An angle chase shows that <Inline tex="S_BS_C \perp AS_A." /> By symmetry, this shows that <Inline tex="J" /> is the orthocenter of <Inline tex="\triangle S_AS_BS_C" /> or <Inline tex="j = s_A + s_B + s_C." /> <p />
                    <Inline tex="S_A" /> is midpoint of an arc <Inline tex="BC." /> Thus, we can find an <Inline tex="\alpha" /> with <Inline tex="|\alpha| = 1" /> such that <Inline tex="b = \alpha s_A, c = \frac{1}{\alpha} s_A." /> Thus, <Inline tex="s_A^2 = bc." /><br />
                    Choose <Inline tex="x" /> such that <Inline tex="x^2 = a." /> Define <Inline tex="y = - \frac{s_C}{x}" /> and <Inline tex="z = - \frac{s_B}{x}." /> Therefore, <Inline tex="s_C = -xy" /> and <Inline tex="s_B = -xz." /> Moreover, <Inline tex="y^2 = b" /> and <Inline tex="z^2 = c." /> Therefore, <Block tex="(yz)^2 = bc = s_A^2" /> or <Inline tex="s_A = \pm yz." /> An angle chase shows that <Block tex="\angle AOS_A + \angle BOS_B + \angle COS_C = 2\pi + \pi." />
                    Since <Inline tex="e^{i3\pi} = -1," /> this shows
                    <Block tex="s_As_Bs_C = -abc = -x^2y^2z^2." />
                    Finally, we obtain <Inline tex="s_A = -yz" /> and thus
                    <Block tex="j = s_A + s_B + s_C = -(xy+yz+zx)." />
                </>
            }]
        }
    },
    REFLECTIONS_AND_PROJECTIONS: function (depth = 0) {
        return {
            id: 17,
            type: "skillEntry",
            title: "Reflections and projections",
            statement: {
                content: <>
                    Let <Inline tex="A \neq B" /> be on the unit circle and <Inline tex="P" /> be some point. Let <Inline tex="Q" /> be the reflection of <Inline tex="P" /> on <Inline tex="AB" /> and let <Inline tex="M" /> be the foot of the altitude of <Inline tex="P" /> on <Inline tex="AB." /> We have
                    <Block tex="q = a + b - ab\bar p," />
                    <Block tex="m = \frac{a + b - ab\bar p + p}{2}." />
                </>
            },
            proofs: [{
                content: <>
                    Complex conjugation corresponds to a reflection on the real line. Thus, we first transform all points by <Inline tex="f" /> with <Inline tex="f(z) = \frac{z-a}{b-a}." /> Note that the reflection property does not change under this spiral similarity. Since the line through <Inline tex="f(a) = 0, f(b) = 1" /> is the real line, we get
                    <Block tex="f(q) = \overline{f(p)}." />
                    Thus,
                    <Block tex="\frac{q-a}{b-a} = \overline{\left(\frac{p-a}{b-a}\right)} = \frac{\bar p - \bar a}{\bar b - \bar a}" />
                    or
                    <Block tex="q = \frac{a\bar b - b\bar a + (b-a) \bar p}{\bar b - \bar a}." />
                    Since <Inline tex="|a| = |b| = 1," /> we have <Block tex="\bar a = \frac{1}{a}, \bar b = \frac{1}{b}." /> Thus,
                    <Block tex="q = a + b - ab \bar p." />
                    The formula for <Inline tex="m" /> follows from
                    <Block tex="m = \frac{p + q}{2}." />
                </>
            }]
        }
    },
    MORE_PROPERTIES: function (depth = 0) {
        return {
            id: 18,
            type: "skillEntry",
            title: "More properties",
            statement: {
                content: <>
                    The first property about triangle areas and thus collinearity can be useful for computations. The others are rarely used. <br />
                    Feel free to confirm these properties.
                </>
            },
            notes: [{
                subtitle: "Oriented area of a triangle",
                content: <>
                    The signed area of <Inline tex="\triangle ABC" /> is <Block tex="\frac{i}{4}(a\bar b - b\bar a + b\bar c - c\bar b + c\bar a - a\bar c) = \frac{i}{4}\begin{vmatrix}a & \bar a & 1\\b & \bar b & 1\\c & \bar c & 1\end{vmatrix}." />
                </>
            },
            {
                subtitle: "Intersection of lines",
                content: <>Let <Inline tex="S" /> be the intersection of lines <Inline tex="AB" /> and <Inline tex="CD." /> We have
                    <Block tex="s = \frac{(a-b)(d\bar c - c\bar d) - (c-d)(b\bar a - a\bar b)}{(a-b)(\bar c - \bar d) - (c-d)(\bar a - \bar b)}." />
                    If <Inline tex="A,B,C,D" /> lie on the unit circle, we have
                    <Block tex="s = \frac{(a+b)cd - (c+d)ab}{cd - ab}." />
                </>
            },
            {
                subtitle: "Concyclicity",
                content: <>
                    Let <Inline tex="A, B, C, D" /> be pairwise distinct. The points <Inline tex="A, B, C, D" /> are concyclic if and only if
                    <Block tex="\frac{c-a}{c-b} : \frac{d-a}{d-b} = \overline{\left(\frac{c-a}{c-b} : \frac{d-a}{d-b}\right)}." />
                </>
            },]
        }
    },
}

export default SkillsData