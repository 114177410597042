import React, {useState} from 'react'

import {Container, Row, Col} from "react-bootstrap"
import {Input} from "reactstrap"
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import SliderStyles from "./SliderStyles"
import {ContestData, BranchData, InfoTagData} from '../../data/TagData'
import { TagField, RangeField } from '../TagField/TagField'

import "./SearchBar.css"
import "../../global.css"
import { useEffect } from 'react'



export function SearchBar({admin, loDiff, setLoDiff, hiDiff, setHiDiff, loYear, setLoYear, hiYear, setHiYear, contests, setContests, addToContests, branches, setBranches, addToBranches, infoTags, setInfoTags, addToInfoTags, getOnClick, getOnClickRemove, toContest, toBranch, toInfoTag, defaultLoDiff, defaultHiDiff, defaultLoYear, defaultHiYear}) {

  const [ availableContests, setAvailableContests ] = useState(Object.keys(ContestData).map((key) => ContestData[key].tag))
  const [ contestSelect, setContestSelect ] = useState(false)
  const contestTextKey = (e) => {
    const eventKey = e.key
    if (eventKey==="Enter") {
      if (availableContests.length > 1) {
        alert("Please select exactly one contest.")
      } else if (availableContests.length === 0) {
        alert("No contest matches the search input.")
      } else {
        const newContestTag = availableContests[0]
        addToContests(newContestTag)
        e.target.value = ""
        updateAvailableContests(e)
        e.target.blur()
      }
    } else if (eventKey==="Escape") {
      e.target.value = ""
      e.target.blur()
    }
  }
  const updateAvailableContests = (e) => {
    e.target.value = e.target.value
    if (e.target.value==="") {
      setAvailableContests(Object.keys(ContestData).map((key) => ContestData[key].tag))
    } else {
      setAvailableContests(Object.keys(ContestData).map(key => ContestData[key]).filter(contest => {
        return contest.tag.toUpperCase().startsWith(e.target.value.toUpperCase()) || contest.title.toUpperCase().startsWith(e.target.value.toUpperCase())
      }).map(contest => contest.tag))
    }
  }



  const [ availableBranches, setAvailableBranches ] = useState(Object.keys(BranchData).map((key) => BranchData[key].tag))
  const [ branchSelect, setBranchSelect ] = useState(false)
  const branchTextKey = (e) => {
    const eventKey = e.key
    if (eventKey==="Enter") {
      if (availableBranches.length > 1) {
        alert("Please select exactly one branch.")
      } else if (availableBranches.length === 0) {
        alert("No branch matches the search input.")
      } else {
        const newBranchTag = availableBranches[0]
        addToBranches(newBranchTag)
        e.target.value = ""
        updateAvailableBranches(e)
        e.target.blur()
      }
    } else if (eventKey==="Escape") {
      e.target.value = ""
      e.target.blur()
    }
  }
  const updateAvailableBranches = (e) => {
    e.target.value = e.target.value
    if (e.target.value==="") {
      setAvailableBranches(Object.keys(BranchData).map((key) => BranchData[key].tag))
    } else {
      setAvailableBranches(Object.keys(BranchData).map(key => BranchData[key]).filter(branch => {return branch.title.toUpperCase().startsWith(e.target.value.toUpperCase())}).map(branch => branch.tag))
    }
  }

  const [ availableInfoTags, setAvailableInfoTags ] = useState(Object.keys(InfoTagData).map((key) => InfoTagData[key].tag))
  const [ infoTagSelect, setInfoTagSelect ] = useState(false)
  const infoTagTextKey = (e) => {
    const eventKey = e.key
    if (eventKey==="Enter") {
      if (availableInfoTags.length > 1) {
        alert("Please select exactly one tag.")
      } else if (availableInfoTags.length === 0) {
        alert("No tag matches the search input.")
      } else {
        const newInfoTag = availableInfoTags[0]
        addToInfoTags(newInfoTag)
        e.target.value = ""
        updateAvailableInfoTags(e)
        e.target.blur()
      }
    } else if (eventKey==="Escape") {
      e.target.value = ""
      e.target.blur()
    }
  }
  const updateAvailableInfoTags = (e) => {
    e.target.value = e.target.value
    if (e.target.value==="") {
      setAvailableInfoTags(Object.keys(InfoTagData).map((key) => InfoTagData[key].tag))
    } else {
      setAvailableInfoTags(Object.keys(InfoTagData).map((key) => InfoTagData[key].tag).filter((tag) => {return tag.toUpperCase().startsWith(e.target.value.toUpperCase())}))
    }
  }

  const setDiff = (values) => {
    setLoDiff(values[0], 12)
    setHiDiff(values[1], 12)
  }
  const setYear = (values) => {
    setLoYear(values[0], 12)
    setHiYear(values[1], 12)
  }
  const sliderStyle = {
    position: 'relative',
    width: '100%',
    height: 80,
  }

  return (
    <div className="content-box input">
      <Container fluid>
        <div className="input-box">
          <div className="input-container slider-container">
            <Row>
              <Col md="6">
                <Row>
                  <div className="input-box-title">
                    Difficulty
                  </div>
                </Row>
                <Row>
                <div className="slider-box">
                  <Slider
                    rootStyle={sliderStyle}
                    domain={[defaultLoDiff, defaultHiDiff]} // [min, max]
                    step={1}
                    mode={2}
                    values={[loDiff, hiDiff]} // slider values
                    onChange={setDiff}
                  >
                    <Rail>
                      {({ getRailProps }) => <SliderStyles.MyRail getRailProps={getRailProps} />}
                    </Rail>
                    <Handles>
                      {({ handles, getHandleProps }) => (
                        <div className="slider-handles">
                          {handles.map(handle => (
                            <SliderStyles.MyHandle
                              key={handle.id}
                              handle={handle}
                              getHandleProps={getHandleProps}
                            />
                          ))}
                        </div>
                      )}
                    </Handles>
                    <Tracks left={false} right={false}>
                      {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                          {tracks.map(({ id, source, target }) => (
                            <SliderStyles.MyTrack
                              key={id}
                              source={source}
                              target={target}
                              getTrackProps={getTrackProps}
                            />
                          ))}
                        </div>
                      )}
                    </Tracks>
                    <Ticks count={5}>
                      {({ ticks }) => (
                        <div className="slider-ticks">
                          {ticks.map(tick => (
                            <SliderStyles.MyTick key={tick.id} tick={tick} count={ticks.length} />
                          ))}
                        </div>
                      )}
                    </Ticks>
                  </Slider>
                </div>
                </Row>
              </Col>
              <Col md="6">
                <SelectedDiffs loDiff={loDiff} setLoDiff={setLoDiff} hiDiff={hiDiff} setHiDiff={setHiDiff} defaultLoDiff={defaultLoDiff} defaultHiDiff={defaultHiDiff} />
              </Col>
            </Row>
          </div>
        </div>
        <div className="input-box">
          <div className="input-container slider-container">
            <Row>
              <Col md="6">
                <Row>
                  <div className="input-box-title">
                    Year
                  </div>
                </Row>
                <Row>
                <div className="slider-box">
                <Slider
                    rootStyle={sliderStyle}
                    domain={[defaultLoYear, defaultHiYear]} // [min, max]
                    step={1}
                    mode={2}
                    values={[loYear, hiYear]} // slider values
                    onChange={setYear}
                  >
                    <Rail>
                      {({ getRailProps }) => <SliderStyles.MyRail getRailProps={getRailProps} />}
                    </Rail>
                    <Handles>
                      {({ handles, getHandleProps }) => (
                        <div className="slider-handles">
                          {handles.map(handle => (
                            <SliderStyles.MyHandle
                              key={handle.id}
                              handle={handle}
                              getHandleProps={getHandleProps}
                            />
                          ))}
                        </div>
                      )}
                    </Handles>
                    <Tracks left={false} right={false}>
                      {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                          {tracks.map(({ id, source, target }) => (
                            <SliderStyles.MyTrack
                              key={id}
                              source={source}
                              target={target}
                              getTrackProps={getTrackProps}
                            />
                          ))}
                        </div>
                      )}
                    </Tracks>
                    <Ticks count={5}>
                      {({ ticks }) => (
                        <div className="slider-ticks">
                          {ticks.map(tick => (
                            <SliderStyles.MyTick key={tick.id} tick={tick} count={ticks.length} />
                          ))}
                        </div>
                      )}
                    </Ticks>
                  </Slider>
                </div>
                </Row>
              </Col>
              <Col md="6">
                <SelectedYears loYear={loYear} setLoYear={setLoYear} hiYear={hiYear} setHiYear={setHiYear} defaultLoYear={defaultLoYear} defaultHiYear={defaultHiYear}/>
              </Col>
            </Row>
          </div>
        </div>
        <div className="input-box">
          <Row>
            <Col md="6">
              <Row>
                <div className="input-box-title">
                  Contest
                </div>
              </Row>
              <Row>
                <div className="input-col">
                  <Input id="contest-text" placeholder="Enter contest" onKeyDown={contestTextKey} onFocus={()=>setContestSelect(true)} onBlur={()=>setContestSelect(false)} onChange={updateAvailableContests}/>
                  <div className="contest-select-box">
                    {!contestSelect ? <></> : <TagField values={toContest(availableContests)} clearValues={null} stationary={false} getOnClick={getOnClick} /> }
                  </div>
                </div>
              </Row>
            </Col>
            <Col md="6">
              <SelectedContests toContest={toContest} contests={contests} setContests={setContests} getOnClickRemove={getOnClickRemove} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Row>
                <div className="input-box-title">
                  Branch
                </div>
              </Row>
              <Row>
                <div className="input-col">
                  <Input id="branch-text" placeholder="Enter branch" onKeyDown={branchTextKey} onFocus={()=>setBranchSelect(true)} onBlur={()=>setBranchSelect(false)} onChange={updateAvailableBranches}/>
                  <div className="branch-select-box">
                    {(!branchSelect) ? (null) : (<TagField values={toBranch(availableBranches)} clearValues={null} stationary={false} getOnClick={getOnClick}/>)}
                  </div>
                </div>
              </Row>
            </Col>
            <Col md="6">
              <SelectedBranches toBranch={toBranch} branches={branches} setBranches={setBranches} getOnClickRemove={getOnClickRemove} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Row>
                <div className="input-box-title">
                  Tag
                </div>
              </Row>
              <Row>
                <div className="input-col">
                  <Input id="tag-text" placeholder="Enter tag" onKeyDown={infoTagTextKey} onFocus={()=>setInfoTagSelect(true)} onBlur={()=>setInfoTagSelect(false)} onChange={updateAvailableInfoTags}/>
                  <div className="tag-select-box">
                    {(!infoTagSelect) ? (null) : (<TagField values={toInfoTag(availableInfoTags)} clearValues={null} stationary={false} getOnClick={getOnClick}/>)}
                  </div>
                </div>
              </Row>
            </Col>
            <Col md="6">
              <SelectedInfoTags toInfoTag={toInfoTag} infoTags={infoTags} setInfoTags={setInfoTags} getOnClickRemove={getOnClickRemove} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}


export function TagBar({admin, loDiff, setLoDiff, hiDiff, setHiDiff, loYear, setLoYear, hiYear, setHiYear, contests, setContests, addToContests, branches, setBranches, addToBranches, infoTags, setInfoTags, addToInfoTags, getOnClickRemove, toContest, toBranch, toInfoTag, defaultLoDiff, defaultHiDiff, defaultLoYear, defaultHiYear}) {

  const [showDiff, setShowDiff] = useState(false)
  const [showYear, setShowYear] = useState(false)
  const [showContest, setShowContest] = useState(false)
  const [showBranch, setShowBranch] = useState(false)
  const [showInfoTag, setShowInfoTag] = useState(false)

  const [showTagField, setShowTagField] = useState(false)

  useEffect(() => {
    const updateTagFields = () => {
      const defaultDiff = (loDiff === defaultLoDiff && hiDiff === defaultHiDiff)
      const defaultYear = (loYear === defaultLoYear && hiYear === defaultHiYear)
      const defaultContest = (contests.length === 0)
      const defaultBranch = (branches.length === 0)
      const defaultInfoTag = (infoTags.length === 0)
      
      setShowDiff(!defaultDiff)
      setShowYear(!defaultYear)
      setShowContest(!defaultContest)
      setShowBranch(!defaultBranch)
      setShowInfoTag(!defaultInfoTag)
      setShowTagField(!(defaultDiff && defaultYear &&  defaultContest && defaultBranch && defaultInfoTag))
    }

    updateTagFields()
  }, [loDiff, hiDiff, loYear, hiYear, contests, branches, infoTags])

  return (
    <>
      {!showTagField ? <></> :
        <div className="content-box input">
          <Container fluid>
            <Row>
              {showDiff ?
                <Col md="3" sm="6">
                  <SelectedDiffs loDiff={loDiff} setLoDiff={setLoDiff} hiDiff={hiDiff} setHiDiff={setHiDiff} defaultLoDiff={defaultLoDiff} defaultHiDiff={defaultHiDiff} />
                </Col>
              :
                <></>
              }
              {showYear ?
                <Col md="3" sm="6">
                  <SelectedYears loYear={loYear} setLoYear={setLoYear} hiYear={hiYear} setHiYear={setHiYear} defaultLoYear={defaultLoYear} defaultHiYear={defaultHiYear} /> 
                </Col>
              :
                <></>
              }
              {showContest ?
                <Col md="3" sm="6">
                  <SelectedContests toContest={toContest} contests={contests} setContests={setContests} getOnClickRemove={getOnClickRemove} />
                </Col>
              :
                <></>
              }
              {showBranch ?
                <Col md="3" sm="6">
                  <SelectedBranches toBranch={toBranch} branches={branches} setBranches={setBranches} getOnClickRemove={getOnClickRemove} />
                </Col>
              :
                <></>
              }
              {showInfoTag ?
                <Col md="3" sm="6">
                  <SelectedInfoTags toInfoTag={toInfoTag} infoTags={infoTags} setInfoTags={setInfoTags} getOnClickRemove={getOnClickRemove} />
                </Col>
              :
                <></>
              }
            </Row>
          </Container>
        </div>
      }
    </>
  )
}

function SelectedDiffs({loDiff, setLoDiff, hiDiff, setHiDiff, defaultLoDiff, defaultHiDiff}) {
  return (
    <>
      <Row>
        <div className="input-box-title">
          Selected difficulty range
        </div>
      </Row>
      <Row>
        <div className="output-col">
          <RangeField loValue={loDiff} hiValue={hiDiff} minValue={defaultLoDiff} maxValue={defaultHiDiff} clearValues={() => {
            setLoDiff(defaultLoDiff)
            setHiDiff(defaultHiDiff)
          }} pluralName="difficulties"/>
        </div>
      </Row>
    </>
  )
}

function SelectedYears({loYear, setLoYear, hiYear, setHiYear, defaultLoYear, defaultHiYear}) {
  return (
    <>
      <Row>
        <div className="input-box-title">
          Selected year range
        </div>
      </Row>
      <Row>
        <div className="output-col">
          <RangeField loValue={loYear} hiValue={hiYear} minValue={defaultLoYear} maxValue={defaultHiYear} clearValues={() => {
            setLoYear(defaultLoYear)
            setHiYear(defaultHiYear)
          }} pluralName="years"/>
        </div>
      </Row>
    </>
  )
}

function SelectedContests({toContest, contests, setContests, getOnClickRemove}) {
  return <>
    <Row>
      <div className="input-box-title">
        Selected contests
      </div>
    </Row>
    <Row>
      <div className="output-col">
        <TagField values={toContest(contests)} clearValues={()=>setContests([])} stationary={true} getOnClick={getOnClickRemove} pluralName="contests"/>
      </div>
    </Row>
  </>
}

function SelectedBranches({toBranch, branches, setBranches, getOnClickRemove}) {
  return <>
    <Row>
      <div className="input-box-title">
        Selected branches
      </div>
    </Row>
    <Row>
      <div className="output-col">
        <TagField values={toBranch(branches)} clearValues={()=>setBranches([])} stationary={true} getOnClick={getOnClickRemove} pluralName="branches"/>
      </div>
    </Row>
  </>
}

function SelectedInfoTags({toInfoTag, infoTags, setInfoTags, getOnClickRemove}) {
  return <>
    <Row>
      <div className="input-box-title">
        Selected tags
      </div>
    </Row>
    <Row>
      <div className="output-col">
        <TagField values={toInfoTag(infoTags)} clearValues={()=>setInfoTags([])} stationary={true} getOnClick={getOnClickRemove} pluralName="tags"/>
      </div>
    </Row>
  </>
}