import React, { useEffect, useState, useRef } from 'react'

import "./LectureHead.css"
import "../../global.css"
function LectureHead({ data, section }) {
    return (
        <div className="lecture-head-background">
            {data.slides.map((slide, index) => {
                let subsection = index + 1
                return <div className="slide-div">
                    <div className="slide-header">
                        <div className="slide-title">
                            {section}. {data.title} <span className="slide-subtitle">{section}.{subsection}. {slide.title}</span>
                        </div>
                        {(slide.cheatsheet === undefined || !slide.cheatsheet) ? <></> :
                            <div className="cheatsheet-title">
                                cheat sheet
                            </div>
                        }
                    </div>
                    {slide.content}
                </div>
            })}
        </div >
    )
}




export default LectureHead