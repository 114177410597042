import React from 'react'

import {AiFillHome, AiFillYoutube} from "react-icons/ai"
import {GiOpenBook} from "react-icons/gi"
import {HiLightBulb} from "react-icons/hi"
import {FaExternalLinkAlt} from "react-icons/fa"
import {GoLaw} from "react-icons/go"
import {BsPersonFill} from "react-icons/bs"
import {FaMedal} from "react-icons/fa"

export const NavbarDataUp = [
  {
    title: "Home",
    titleIcon: <></>,
    path: "/",
    icon: <AiFillHome />,
    cName: "nav-item",
  },
  {
    title: "Problems and solutions",
    titleIcon: <></>,
    path: "/problems-solutions",
    icon: <HiLightBulb />,
    cName: "nav-item",
  },
  {
    title: "Wiki",
    titleIcon: <></>,
    path: "/wiki",
    icon: <GiOpenBook />,
    cName: "nav-item",
  },
  {
    title: "Youtube",
    titleIcon: <FaExternalLinkAlt size = {10}/>,
    url: "https://www.youtube.com/channel/UC-DagxlU75SXG0FntGxKvTA",
    icon: <AiFillYoutube />,
    cName: "nav-item",
  },
  {
    title: "Skillpages",
    titleIcon: <></>,
    path: "/skillpages",
    icon: <FaMedal />,
    cName: "nav-item",
    brandNew: false,
  }
]

export const NavbarDataDown = [
  {
    title: "About and Contact",
    titleIcon: <></>,
    path: "/about+contact",
    icon: <BsPersonFill />,
    cName: "nav-item",
  },
  {
    title: "Legal Disclosure",
    titleIcon: <></>,
    path: "/legal-disclosure",
    icon: <GoLaw />,
    cName: "nav-item",
  },
]

export const NavbarDataAdmin = [
]