import React, {useState, useEffect} from 'react'
import { useLocation, Navigate, useNavigate, Link } from 'react-router-dom'
import {Container, Row, Col} from "react-bootstrap"
import Scroll from 'react-scroll'

import {BsPersonFill} from "react-icons/bs"

import "./Legal.css"
import "../../global.css"

import { ScrollToTopOnMount } from '../../functions/ScrollToTop'

const ScrollLink = Scroll.Link

function Legal() {

  const table = [
    {
      category: <>Full names</>,
      value: <>Timo Lörke, Richard Ueltzen</>,
    },
    {
      category: <>Address</>,
      value: <>Rochusstraße 138-140</>,
    },
    {
      category: <>Postal code and city</>,
      value: <>53123 Bonn</>,
    },
    {
      category: <>Mail</>,
      value: <a href= "mailto:contact@calimath.org" className="primary-text">contact@calimath.org</a>
    },
    {
      category: <>Contact form</>,
      value: <Link to="/about+contact" activeClass="active" spy={true} smooth={true}>
        <span className="primary-text">
          <BsPersonFill />
        </span>
      </Link>
    }
  ]

  useEffect(() => {
    document.title = "Calimath - Legal disclosure"
  }, [])

  return (
    <Container fluid>
    <ScrollToTopOnMount/>
      <div className="content-box">
          <h1>
              Legal Disclosure
          </h1>
          {table.map((entry, index) => {
            return <Row className="table-row" key={index}>
              <Col xs="12" sm="6" className="table-col">
                <div className="table-entry">
                  {entry.category}
                </div>
              </Col>
              <Col xs="12" sm="6" className="table-col">
                <div className="table-entry">
                  {entry.value}
                </div>
              </Col>
            </Row>
          })}
      </div>
    </Container>
  )
}

export default Legal