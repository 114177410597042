import React, { useState, useEffect, useRef } from 'react'

import "./SkillField.css"
import "../../global.css"

import PdfButton from '../MyButton/PdfButton'
import SkillButton from '../MyButton/SkillButton'


function SkillField({ admin, customDate, date, skill, showMoreInfoId, updateShowMoreInfoId, infoRefs, setActiveInfoId }) {

    return (
        <div>
            <div id="skill-field-title">
                {skill.title}
            </div>
            <div>
                {
                    skill.pdfs.map(pdf => {
                        const pdfContent = pdf()
                        return <div key={pdfContent.type + pdfContent.id}>
                            <PdfButton admin={admin} customDate={customDate} date={date} showMoreId={showMoreInfoId} updateShowMoreId={updateShowMoreInfoId} pdfRefs={infoRefs} file={pdfContent} getOnClick={() => 0} problemSearch={false} problemDelete={false} skill={true} />
                        </div>
                    })
                }
                {
                    skill.content.map(info => {
                        const infoPage = info()
                        return <div key={infoPage.type + infoPage.id}>
                            <SkillButton admin={admin} customDate={customDate} date={date} data={infoPage} showMoreId={showMoreInfoId} updateShowMoreId={updateShowMoreInfoId} infoRefs={infoRefs} />
                        </div>
                    })
                }
            </div>
        </div>
    )
}


export default SkillField