import React, { useEffect, useState } from 'react'

function GuardRelease({ children }) {

    return (
        <>
            {true ? children : <></>}
        </>
    )
}


export default GuardRelease