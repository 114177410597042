import { toPath } from "./toPath"

export const navigateToWikiPage = (title, location, navigate) => {
  if("/wiki/" + wikiTitleToPath(title) !== location.pathname) {
    navigate("/wiki/" + wikiTitleToPath(title))
  }
}

export const wikiTitleToPath = (title) => {
  if (title === "Wiki Homepage") {
    return ""
  }
  return toPath(title)
}