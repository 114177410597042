import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Container, Row, Col } from "react-bootstrap"
import { Input } from "reactstrap"
import WikiData from '../../data/WikiData'
import TextField from '../../elements/TextField/TextField'

import WikiPage from '../../elements/WikiPage/WikiPage'
import NotFoundPage from '../NotFoundPage/NotFoundPage'

import "./Wiki.css"
import "../../global.css"

import { wikiTitleToPath, navigateToWikiPage } from '../../functions/navigateToWikiPage'
import { toPath } from '../../functions/toPath'


function Wiki({ admin, customDate, date, fullWiki }) {
    const pageFieldRef = useRef()
    const fieldElementRefs = useRef({})
    const location = useLocation()
    const navigate = useNavigate()

    const [titleInfo, setTitleInfo] = useState(undefined)
    const [searchInput, setSearchInput] = useState("")
    const [availablePages, setAvailablePages] = useState([])
    const [pageSelect, setPageSelect] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)

    const incrementSelectedIndex = () => {
        if (selectedIndex == -1) {
            setSelectedIndex(0)
            return
        }
        setSelectedIndex((selectedIndex + 1) % availablePages.length)
    }

    const decrementSelectedIndex = () => {
        if (selectedIndex === -1) {
            setSelectedIndex(availablePages.length - 1)
            return
        }
        setSelectedIndex((selectedIndex + availablePages.length - 1) % availablePages.length)
    }

    const updateInnerScroll = () => {
        const e = fieldElementRefs.current[selectedIndex]

        const y = e.getBoundingClientRect().top - pageFieldRef.current.getBoundingClientRect().top

        const yMin = 50
        const yMax = 150

        let yScroll = 0

        if (y < yMin) { yScroll = y - yMin }
        if (y > yMax) { yScroll = y - yMax }

        pageFieldRef.current.scrollBy({
            top: yScroll,
            behavior: "auto",
        })
    }

    const titleToPage = (title) => {
        return Object.keys(WikiData).map(key => WikiData[key]()).find(page => title === page.title)
    }

    const pathToTitle = (location) => {
        const pathTitle = location.pathname.slice(6)
        if (pathTitle === "") {
            return ["Wiki Homepage", false]
        }
        const page = Object.keys(WikiData).map(key => WikiData[key]()).find(page => (pathTitle === toPath(page.title) || page.altTitles !== undefined && page.altTitles.map(title => { return toPath(title) }).includes(pathTitle)))
        const throw404 = typeof page === "undefined"
        return [throw404 ? "" : page.title, throw404]
    }

    const clearSearch = (e) => {
        e.target.value = ""
        e.target.blur()
        resetSelectedIndex()
    }

    const resetSelectedIndex = () => {
        if (window.innerWidth < 1000) {
            setSelectedIndex(-1)
        } else {
            setSelectedIndex(0)
        }
    }

    useEffect(() => {
        resetSelectedIndex()
    }, [])

    const pageTextKey = (e) => {
        resetSelectedIndex()
        const eventKey = e.key
        if (eventKey === "Enter") {
            if (availablePages.length === 1) {
                navigateToWikiPage(availablePages[0], location, navigate)
                clearSearch(e)
            } else if (selectedIndex !== -1) {
                navigateToWikiPage(availablePages[selectedIndex], location, navigate)
                clearSearch(e)
            }
        } else if (eventKey === "Escape") {
            e.target.value = ""
            e.target.blur()
        } else if (eventKey === "ArrowDown") {
            incrementSelectedIndex()
        } else if (eventKey === "ArrowUp") {
            decrementSelectedIndex()
        }
    }

    useEffect(() => {
        try {
            updateInnerScroll()
        } catch {
            return
        }
    }, [selectedIndex])

    const updateAvailablePages = () => {
        setAvailablePages(Object.keys(
            WikiData
        ).map((key) => {
            return WikiData[key]()
        }).filter(page => {
            return page.title !== "Wiki Homepage"
        }).filter(page => {
            let titlePage = simplify(page.title).includes(simplify(searchInput))
            titlePage = titlePage || (page.altTitles !== undefined && page.altTitles.reduce((altTitlePage, altTitle) => {
                return altTitlePage || simplify(altTitle).includes(simplify(searchInput))
            }, false))
            return titlePage
        }).map(page => {
            return page.title
        }).sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        }))
    }

    const simplify = text => {
        if (typeof text === "undefined") return ""
        return text.toLowerCase().replace(/[^a-z0-9]/g, '')
    }

    const getOnClick = (title) => () => {
        navigateToWikiPage(title, location, navigate)
        document.activeElement.value = ""
        document.activeElement.blur()
    }

    useEffect(() => {
        updateAvailablePages()
    }, [searchInput, customDate, date])

    useEffect(() => {
        if (typeof titleInfo === "undefined" || "/wiki/" + wikiTitleToPath(titleInfo[0]) !== location.pathname) {
            setTitleInfo(pathToTitle(location))
        }
    }, [location])

    useEffect(() => {
        if (typeof titleInfo !== "undefined") {
            document.title = "Calimath - " + titleInfo[0]
        }
    }, [titleInfo])

    return (
        <>
            {typeof titleInfo === "undefined" ? <></> :
                (titleInfo[1] ?
                    <NotFoundPage />
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <div className="content-box">
                                    <Row>
                                        <h1 className="problems-title-col">
                                            Wiki
                                        </h1>
                                    </Row>
                                    <Row>
                                        <div className="text-input-col wiki-col">
                                            <Input id="page-text" placeholder="Enter wiki page"
                                                onKeyDown={pageTextKey}
                                                onFocus={() => setPageSelect(true)}
                                                onBlur={() => {
                                                    setPageSelect(false)
                                                    setSearchInput("")
                                                    resetSelectedIndex()
                                                }}
                                                onChange={e => setSearchInput(e.target.value)} />
                                            <div className="page-select-box">
                                                {(!pageSelect) ? (null) : (
                                                    <>
                                                        <TextField titles={availablePages} selectedIndex={selectedIndex} fieldRef={pageFieldRef} fieldElementRefs={fieldElementRefs} getOnClick={getOnClick} />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                {!fullWiki ?
                                    <WikiPage admin={admin} page={titleToPage(titleInfo[0])} customDate={customDate} date={date} />
                                    :
                                    Object.keys(WikiData).map(key => WikiData[key]()).map(page => {
                                        return <WikiPage admin={admin} page={page} customDate={customDate} date={date} />
                                    })
                                }
                            </Col>
                        </Row>
                    </Container>
                )
            }
        </>
    )
}

export default Wiki