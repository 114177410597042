import React, {useEffect, useState, useRef} from 'react'

import "./ChannelBanner.css"
import "../../global.css"

import CalimathLogoElementary from "../../img/difficulty-logos/elementary.png"
import CalimathLogoMedium from "../../img/difficulty-logos/medium.png"
import CalimathLogoIntermediate from "../../img/difficulty-logos/intermediate.png"
import CalimathLogoAdvanced from "../../img/difficulty-logos/advanced.png"
import CalimathLogoExpert from "../../img/difficulty-logos/expert.png"

import exportAsImage from '../../functions/exportAsImage'


function ChannelBanner({problems}) {
  const exportRef = useRef();

  function difficultyTranslation (num) {
    if (num <= 5) {
      return "elementary"
    } else if (num <= 7) {
      return "medium"
    } else if (num <= 9) {
      return "intermediate"
    } else if (num <= 11) {
      return "advanced"
    } else {
      return "expert"
    }
  }

  return (
    <div ref={exportRef} className="channel-banner-div" onClick={() => {exportAsImage(exportRef.current, "ChannelBanner")}}>
      <div className="channel-banner-ctr">
        <div className="channel-banner-title">
          Calimath
        </div>
        <div className="problem-titles-div">
          {problems.sort((a, b) => {
            return a.difficulty.tag < b.difficulty.tag
          }).map(problem => {
            return <span className={"problem-span " + difficultyTranslation(problem.difficulty.tag)}>
              {problem.title} &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
          })}
        </div>
        <div className="difficulties-div">
          <div className="difficulty-div elementary">
            <img src={CalimathLogoElementary} alt="Calimath logo" className="banner-difficulty-logo"/>
            elementary
          </div>
          <div className="difficulty-div medium">
            <img src={CalimathLogoMedium} alt="Calimath logo" className="banner-difficulty-logo"/>
            medium
          </div>
          <div className="difficulty-div intermediate">
            <img src={CalimathLogoIntermediate} alt="Calimath logo" className="banner-difficulty-logo"/>
            intermediate
          </div>
          <div className="difficulty-div advanced">
            <img src={CalimathLogoAdvanced} alt="Calimath logo" className="banner-difficulty-logo"/>
            advanced
          </div>
          <div className="difficulty-div expert">
            <img src={CalimathLogoExpert} alt="Calimath logo" className="banner-difficulty-logo"/>
            expert
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChannelBanner