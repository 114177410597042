/* global $ */

const currentTime = (customDate, date) => {
  if (customDate) {
    return date.getTime()
  }
  else {
    return serverTime()
  }
}

const compTime = (customDate, compTime) => {
  if (customDate && typeof compTime === "undefined") {
    return toDate(3000, 1, 1, 0, 0).getTime()
  }
  return compTime
}

export const serverTime = () => {
  return (new Date(getServerTime())).getTime()
}

export const publicTime = (custom, date, compareTime) => {
  return currentTime(custom, date) >= compTime(custom, compareTime)
}

export const toDate = (year, month, day, hour, minute) => {
  return new Date(year, month-1, day, hour, minute)
}

export const formatTime = time => {
  const date = new Date(time)
  return "" + date.getDate() + "." + (parseInt(date.getMonth()) + 1) + "." + date.getFullYear() + ", " + fullTime(date.getHours()) + ":" + fullTime(date.getMinutes())
}

const fullTime = timeString => {
  if (timeString < 10) {
    return "0" + timeString
  }
  else {
    return timeString
  }
}

let lastTimeHTTP = {
  serverTime: 0,
  browserTime: 0,
}

function getServerTime() {
  if (Date.now() - lastTimeHTTP.browserTime > 1000) {
    lastTimeHTTP = {
      serverTime: (new Date($.ajax({async: false}).getResponseHeader( 'Date' ))).getTime(),
      browserTime: Date.now()
    }
  }
  return lastTimeHTTP.serverTime + Date.now() - lastTimeHTTP.browserTime
}