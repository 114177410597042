/* global $ */

import React, {useState, useEffect} from 'react'
import { BsLink } from 'react-icons/bs'
import PopUp from '../PopUp/PopUp'

import "./CopyToClipboard.css"

function CopyToClipboard({ text }) {
  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard() {
    return await navigator.clipboard.writeText(text)
  }

  const onClick = () => {
    copyTextToClipboard()
    .then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return <div id="copy-outer">
    <BsLink className="clickable-icon" onClick={onClick}/>
  </div>
}

export default CopyToClipboard