import { Block, Inline } from "../elements/MyMath/MyMath"
import "./data.css"

export const LectureHeadData = {
    PROBABILITY_SPACE: {
        title: "Probability spaces",
        slides: [
            {
                title: "Definition",
            },
            {
                title: "Elementary properties",
                content: <div className="slide-content cheat">
                    <div className="cheat-column" id="probability-measure">
                        <div>
                            <Inline tex="\mathcal A \subseteq 2^\Omega \, \sigma\text{-algebra}: A \in \mathcal A \implies A^c \in \mathcal A" />
                        </div>
                        <div>
                            <Inline tex="A_1, A_2, \dots \in \mathcal A \implies \bigcup_i A_i \in \mathcal A, \Omega \in \mathcal A" />
                        </div>
                    </div>
                    <div className="cheat-column">
                        <div>
                            <Inline tex="\mathbb P \,\text{probability distribution on}\, (\Omega, \mathcal A): \mathbb P[\Omega] = 1" />
                        </div>
                        <div>
                            <Inline tex="A_1, A_2, \dots \in \mathcal A \,\text{disjoint} \implies \mathbb P[\bigcup A_i] = \sum \mathbb P[A_i]" />
                        </div>
                    </div>
                </div>,
                cheatsheet: true
            },
            {
                title: "Union bound",
                content: <div className="slide-content" id="union-bound"><Inline tex="\mathbb P \left[\bigcup_{i=1}^\infty A_i \right] \leq \sum_{i=1}^\infty \mathbb P[A_i]" />
                </div >
            },
            {
                title: "Principle of inclusion-exclusion",
                content: <div className="slide-content" id="pie">
                    <Inline tex="\mathbb P \left[\bigcup_{i=1}^n A_i \right] = \sum_{k=1}^n (-1)^{k-1} \sum_{1 \leq i_1 < \dots < i_k \leq n} \mathbb P[A_{i_1} \cap \dots \cap A_{i_k}]" />
                </div >
            },
        ]
    },
    RANDOM_VARIABLE: {
        title: "Random variables",
        slides: [
            {
                title: "Definition",
                content: <div className="slide-content cheat">
                    <div className="cheat-column" id="probability-measure">
                        <div>
                            <Inline tex="\mathcal A \subseteq 2^\Omega \, \sigma\text{-algebra}: A \in \mathcal A \implies A^c \in \mathcal A" />
                        </div>
                        <div>
                            <Inline tex="A_1, A_2, \dots \in \mathcal A \implies \bigcup_i A_i \in \mathcal A, \Omega \in \mathcal A" />
                        </div>
                    </div>
                    <div className="cheat-column">
                        <div>
                            <Inline tex="\mathbb P \,\text{probability distribution on}\, (\Omega, \mathcal A): \mathbb P[\Omega] = 1" />
                        </div>
                        <div>
                            <Inline tex="A_1, A_2, \dots \in \mathcal A \,\text{disjoint} \implies \mathbb P[\bigcup A_i] = \sum \mathbb P[A_i]" />
                        </div>
                    </div>
                </div>,
                cheatsheet: true
            },
            {
                title: "Transformation (part I)",
                content: <div className="slide-content" id="trafo-formula">
                    <Inline tex="g : \mathcal S \to \mathcal T \implies g(X) := g \circ X\," /> is a random variable
                </div >
            },
            {
                title: "Indicator function",
                content: <div className="slide-content cheat">
                    <div className="cheat-column">
                        <div>
                            <Inline tex="\mathbb P \,\text{probability distribution on}\, (\Omega, \mathcal A): \mathbb P[\Omega] = 1" />
                        </div>
                        <div>
                            <Inline tex="A_1, A_2, \dots \in \mathcal A \,\text{disjoint} \implies \mathbb P[\bigcup A_i] = \sum \mathbb P[A_i]" />
                        </div>
                    </div>
                    <div className="cheat-column" id="probability-measure">
                        <div className="cheat-entry">
                            <Inline tex="X : \Omega \to \mathcal S" /> is a RV: <Inline tex="\forall x \in \mathcal S: \{X = x\} \in \mathcal A" /><br />
                            <Inline tex="g : \mathcal S \to \mathcal T \implies g(X)" /> is a RV
                        </div>
                    </div>
                </div>,
                cheatsheet: true
            },
        ]
    },
    EXPECTATION: {
        title: "Expectation",
        slides: [
            {
                title: "Definition",
                content: <div className="slide-content cheat">
                    <div className="cheat-column" id="probability-measure">
                        <div className="cheat-entry">
                            <Inline tex="X : \Omega \to \mathcal S" /> RV: <Inline tex="\forall x \in \mathcal S: \{X = x\} \in \mathcal A" /><br />
                            <Inline tex="g : \mathcal S \to \mathcal T \implies g(X)" /> is a RV
                        </div>
                    </div>
                    <div className="cheat-column" id="probability-measure">
                        <Inline tex="\mathbb 1_A(\omega) = \begin{cases} 1 & \text{for $\omega \in A$,}\\ 0 & \text{otherwise.} \end{cases}" />
                    </div>
                </div>,
                cheatsheet: true
            },
            {
                title: "Transformation (part II)",
                content: <div className="slide-content" id="trafo-formula">
                    <Inline tex="g : X(\Omega) \to \mathbb R \implies \mathbb E[g(X)] = \sum_{x \in X(\Omega)} g(x) \cdot \mathbb P[X = x]" />
                </div >
            },
            {
                title: "Linearity of expectation",
                content: <div className="slide-content cheat">
                    <div className="cheat-column" id="probability-measure">
                        <div className="cheat-entry">
                            <Inline tex="X : \Omega \to \mathcal S" /> RV: <Inline tex="\forall x \in \mathcal S: \{X = x\} \in \mathcal A" /><br />
                            <Inline tex="g : \mathcal S \to \mathcal T \implies g(X)" /> is a RV
                        </div>
                    </div>
                    <div className="cheat-column" id="probability-measure">
                        <Inline tex="\mathbb 1_A(\omega) = \begin{cases} 1 & \text{for $\omega \in A$,}\\ 0 & \text{otherwise.} \end{cases}" />
                    </div>
                </div>,
                cheatsheet: true
            },
            {
                title: "Monotony",
                content: <div className="slide-content" id="union-bound"><Inline tex="X \geq Y \implies \mathbb E[X] \geq \mathbb E[Y]" />
                </div >
            },
            {
                title: "Markov\'s inequality",
                content: <div className="slide-content" id="union-bound"><Inline tex="X \geq 0, \lambda > 0 \implies \mathbb P\left[X \geq \lambda \right] \leq \mathbb E[X] / \lambda" />
                </div >
            },
        ]
    },
    VARIANCE: {
        title: "Variance",
        slides: [
            {
                title: "Definition",
                content: <div className="slide-content cheat">
                    <div className="cheat-column" id="probability-measure">
                        <div className="cheat-entry" id="exp-reminder">
                            <Inline tex="\mathbb E[X] = \sum_{x \in X(\Omega)} x \cdot \mathbb P[X = x]" />
                        </div>
                    </div>
                    <div className="cheat-column" id="probability-measure">
                        <Inline tex="\mathbb E[\mathbb 1_A] = \mathbb P[A]" />
                        <Inline tex="\mathbb E[\alpha X + \beta Y] = \alpha \cdot \mathbb E[X] + \beta \cdot \mathbb E[Y]" />
                    </div>
                </div>,
                cheatsheet: true
            },
            {
                title: "Chebyshev's inequality",
                content: <div className="slide-content cheat">
                    <div className="cheat-column" id="probability-measure">
                        Markov's inequality<br />
                        <Inline tex="X \geq 0, \lambda > 0 \implies \mathbb P\left[X \geq \lambda \right] \leq \mathbb E[X] / \lambda" />
                    </div>
                    <div className="statement-column" id="probability-measure">
                        Chebyshev's inequality<br />
                        <Inline tex="\lambda > 0 \implies \mathbb P[|X - \mathbb E[X]| \geq \lambda] \leq \mathrm{Var}[X] / \lambda^2" />
                    </div>
                </div>,
                cheatsheet: true
            },
            {
                title: "Exercise",
                content: <div className="slide-content" id="union-bound"><Inline tex="\mathbb P[X \geq \mathbb E[X] + \lambda] \leq \mathrm{Var}[X] / (\mathrm{Var}[X] + \lambda^2)" />
                </div >
            },
            {
                title: "Deterministic random variables",
                content: <div className="slide-content cheat">
                    <div className="cheat-column" id="var-reminder">
                        <div className="cheat-entry">
                            <Inline tex="\mathrm{Var}[X] = \mathbb E [(X - \mathbb E [X])^2] = \mathbb E[X^2] - \mathbb[X]^2" />
                        </div>
                    </div>
                    <div className="cheat-column">
                        Chebyshev's inequality<br />
                        <Inline tex="\lambda > 0 \implies \mathbb P[|X - \mathbb E[X]| \geq \lambda] \leq \mathrm{Var}[X] / \lambda^2" />
                    </div>
                </div>,
                cheatsheet: true
            },
        ]
    }
}