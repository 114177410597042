import React from 'react'
import "../ProblemTag/ProblemTag.css"


import "./TextField.css"
import "../../global.css"

function TextField({titles, selectedIndex, fieldRef, fieldElementRefs, getOnClick}) {
  return (
    <div ref={fieldRef} onMouseDown={(e)=>e.preventDefault()}
      className="my-text-field">
      {titles.length === 0 ? 
        <>
          There is no page matching the search input.
        </>
      :
        titles.map((title, index) => {
          return (
            <div ref={e => fieldElementRefs.current[index] = e} key={index} className={"primary-text text-field-div " + (index===selectedIndex ? "highlighted-text-field" : "normal-text-field")} onClick={getOnClick(title)}>
              <div className="inner-text-field-div">
                {title}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}


export default TextField