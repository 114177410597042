export const ContestData = {
    CNO: {
        dataClass: "Contest",
        tag: "CNO",
        title: "Chinese National Olympiad",
    },
    USAMO: {
        dataClass: "Contest",
        tag: "USAMO",
        title: "USA Mathematical Olympiad",
        url: "https://www.maa.org/math-competitions/united-states-of-america-mathematical-olympiad-usamo-1",
    },
    APMO: {
        dataClass: "Contest",
        tag: "APMO",
        title: "Asian Pacific Mathematics Olympiad",
        url: "https://www.apmo-official.org/",
    },
    EGMO: {
        dataClass: "Contest",
        tag: "EGMO",
        title: "European Girls' Mathematical Olympiad",
        url: "https://www.egmo.org/",
    },
    IMO: {
        dataClass: "Contest",
        tag: "IMO",
        title: "International Mathematical Olympiad",
        url: "https://www.imo-official.org/?language=en",
    },
    MEMO: {
        dataClass: "Contest",
        tag: "MEMO",
        title: "Middle European Mathematical Olympiad",
        url: "https://www.memo-official.org/",
    },
    USA_TST: {
        dataClass: "Contest",
        tag: "USA TST",
        title: "USA team selection test for the IMO",
    },
    USA_TSTST: {
        dataClass: "Contest",
        tag: "USA TSTST",
        title: "USA team selection test for the team selection test for the IMO",
    },
    ISL: {
        dataClass: "Contest",
        tag: "ISL",
        title: "IMO Shortlist",
        url: "https://www.imo-official.org/problems.aspx",
    },
    BWM: {
        dataClass: "Contest",
        tag: "BWM",
        title: "Bundeswettbewerb Mathematik",
        url: "https://www.mathe-wettbewerbe.de/bundeswettbewerb-mathematik",
    },
    Swiss_TST: {
        dataClass: "Contest",
        tag: "SWISS TST",
        title: "Swiss team selection test for the IMO",
    },
    India_EGMO_TST: {
        dataClass: "Contest",
        tag: "INDIA EGMO TST",
        title: "Indian team selection test for the EGMO",
    },
    Bulgaria_NMO: {
        dataClass: "Contest",
        tag: "Bulgaria NMO",
        title: "Bulgarian national math olympiad",
    },
    BALTIC_WAY: {
        dataClass: "Contest",
        tag: "Baltic Way",
        title: "Baltic Way",
    },
    VNO: {
        dataClass: "Contest",
        tag: "VNO",
        title: "Vietnam National Olympiad",
    },
    CMO: {
        dataClass: "Contest",
        tag: "CMO",
        title: "Canadian Mathematical Olympiad",
        url: "https://cms.math.ca/competitions/cmo/",
    },
    INMO: {
        dataClass: "Contest",
        tag: "INMO",
        title: "Indian National Mathematical Olympiad",
    },
    JAPAN_MO: {
        dataClass: "Contest",
        tag: "Japan MO",
        title: "Japan Math Olympiad",
    },
    ITAMO: {
        dataClass: "Contest",
        tag: "ITAMO",
        title: "Italian Math Olympiad",
    },
    CMC: {
        dataClass: "Contest",
        tag: "CMC",
        title: "China National High School Mathematics League",
    },
    GMO: {
        dataClass: "Contest",
        tag: "GMO",
        title: "German Math Olympiad",
        url: "https://www.mathematik-olympiaden.de/moev/index.php/aufgaben/aufgabenarchiv",
    },
    ROMANIA_TST: {
        dataClass: "Contest",
        tag: "Romania TST",
        title: "Romania team selection test for the IMO",
    },
    OLYMPIAD_PROBLEMS: {
        dataClass: "Contest",
        tag: "Olympiad Problems",
        title: "Calimath collection of olympiad problems",
    },
    BENELUX: {
        dataClass: "Contest",
        tag: "Benelux",
        title: "Benelux Math Olympiad",
    },
    SERBIA_MO: {
        dataClass: "Contest",
        tag: "Serbia MO",
        title: "Serbia Math Olympiad",
    },
    BELARUS_TST: {
        dataClass: "Contest",
        tag: "Belarus TST",
        title: "Belarus team selection test for the IMO",
    },
    RMM: {
        dataClass: "Contest",
        tag: "RMM",
        title: "Romanian Masters of Mathematics",
    },
    RMM_SL: {
        dataClass: "Contest",
        tag: "RMM SL",
        title: "Romanian Masters of Mathematics Shortlist",
    },
    TAIWAN_MO: {
        dataClass: "Contest",
        tag: "Taiwan MO",
        title: "Taiwan Mathematics Olympiad",
    },
    TURKEY_MO: {
        dataClass: "Contest",
        tag: "Turkey MO",
        title: "Turkey MO",
    },
    ESTONIA_TST: {
        dataClass: "Contest",
        tag: "Estonia TST",
        title: "Estonia TST",
    },
    THEOREM: {
        dataClass: "Contest",
        tag: "Theorems",
        title: "Calimath collection of theorems",
    },
    IRAN_MO: {
        dataClass: "Contest",
        tag: "Iran MO",
        title: "Iran Math Olympiad",
    },
    COURSE: {
        dataClass: "Contest",
        tag: "Course",
        title: "Calimath Courses",
    },
    BRITISH_MO: {
        dataClass: "Contest",
        tag: "British MO",
        title: "British Math Olympiad",
        url: "https://bmos.ukmt.org.uk/",
    },
    CHINA_TST: {
        dataClass: "Contest",
        tag: "China TST",
        title: "China TST",
    },
    BALKAN_SL: {
        dataClass: "Contest",
        tag: "BSL",
        title: "Balkan Shortlist",
    },
    BALKAN_MO: {
        dataClass: "Contest",
        tag: "BMO",
        title: "Balkan Math Olympiad",
    },
    THAILAND_ONLINE_MO: {
        dataClass: "Contest",
        tag: "TOMO",
        title: "Thailand Online MO",
    },
    ELMO: {
        dataClass: "Contest",
        tag: "ELMO",
        title: "ELMO",
        url: "https://web.evanchen.cc/elmo/index.html",
    },
    USA_EGMO_TST: {
        dataClass: "Contest",
        tag: "USA EGMO TST",
        title: "USA EGMO Team Selection Test",
    },
    IMOC: {
        dataClass: "Contest",
        tag: "IMOC",
        title: "IMOC"
    },
    IMC: {
        dataClass: "Contest",
        tag: "IMC",
        title: "International Mathc Competition"
    },
    SWISS_MO: {
        dataClass: "Contest",
        tag: "Swiss MO",
        title: "Swiss Math Olympiad"
    },
    FRANCE_TST: {
        dataClass: "Contest",
        tag: "France TST",
        title: "French team selection test for the IMO",
    },
    BELARUS_MO: {
        dataClass: "Contest",
        tag: "Belarus MO",
        title: "Belarus Math Olympiad"
    },
    BRAZIL_TST: {
        dataClass: "Contest",
        tag: "Brazil TST",
        title: "Brazil team selection test for the IMO",
    },
    IRAN_TST: {
        dataClass: "Contest",
        tag: "Iran TST",
        title: "Iran team selection test for the IMO",
    },
    IZHO: {
        dataClass: "Contest",
        tag: "IZhO",
        title: "International Zhautykov Olympiad",
    },
    MALAYSIA_APMO_TST: {
        dataClass: "Contest",
        tag: "Malaysia APMO TST",
        title: "Malaysia APMO TST",
    },
    USEMO: {
        dataClass: "Contest",
        tag: "USEMO",
        title: "US Ersatz Math Olympiad",
        url: "https://web.evanchen.cc/usemo.html",
    },
}

export const BranchData = {
    A: {
        dataClass: "Branch",
        tag: "A",
        title: "Algebra",
    },
    C: {
        dataClass: "Branch",
        tag: "C",
        title: "Combinatorics",
    },
    G: {
        dataClass: "Branch",
        tag: "G",
        title: "Geometry",
    },
    N: {
        dataClass: "Branch",
        tag: "N",
        title: "Number Theory",
    },
}

export const YearData = (year) => {
    const yearData = {
        dataClass: "Year",
        tag: year,
        title: year,
    }
    return yearData
}

export const DifficultyData = (difficulty) => {
    const difficultyData = {
        dataClass: "Difficulty",
        tag: difficulty,
        title: difficulty,
    }
    return difficultyData
}

export const InfoTagData = {
    FUNCTIONAL_EQUATION: {
        dataClass: "Info",
        tag: "Functional equation",
        title: "Functional equations",
    },
    NT_FUNCTION: {
        dataClass: "Info",
        tag: "NT function",
        title: "Number theoretic functions",
    },
    DIOPHANTINE_EQUATION: {
        dataClass: "Info",
        tag: "Diophantine equation",
        title: "Diophantine equations",
    },
    INEQUALITY: {
        dataClass: "Info",
        tag: "Inequality",
        title: "Inequalities",
    },
    GRAPH_THEORY: {
        dataClass: "Info",
        tag: "Graph theory",
        title: "Graph theory",
    },
    COMBINATORIC_IDENTITY: {
        dataClass: "Info",
        tag: "Combinatoric identity",
        title: "Combinatoric identities",
    },
    DIVISIBILITY: {
        dataClass: "Info",
        tag: "Divisibility",
        title: "Divisibility",
    },
    SEQUENCE: {
        dataClass: "Info",
        tag: "Sequence",
        title: "Sequences",
    },
    PROBABILITIES: {
        dataClass: "Info",
        tag: "Probability",
        title: "Probabilities",
    },
    FACTORIZATION: {
        dataClass: "Info",
        tag: "Factorization",
        title: "Factorizations",
    },
    COLORINGS: {
        dataClass: "Info",
        tag: "Colorings",
        title: "Colorings",
    },
    POLYNOMIALS: {
        dataClass: "Info",
        tag: "Polynomials",
        title: "Polynomials",
    },
    ENUMERATIVE_COMBINATORICS: {
        dataClass: "Info",
        tag: "Enumerative Combi",
        title: "Enumerative Combinatorics",
    },
    ANGLE_CHASING: {
        dataClass: "Info",
        tag: "Angle chasing",
        title: "Angle chasing",
    },
    LENGTHS_AND_RATIOS: {
        dataClass: "Info",
        tag: "Lengths and ratios",
        title: "Lengths and ratios",
    },
    PRIME_FACTORS: {
        dataClass: "Info",
        tag: "Prime factors",
        title: "Prime factors",
    },
    COMPLEX_NUMBERS: {
        dataClass: "Info",
        tag: "Complex numbers",
        title: "Complex numbers",
    },
    TRIGONOMETRY: {
        dataClass: "Info",
        tag: "Trig",
        title: "Trigonometry",
    },
    GEOMETRY_CROSSOVER: {
        dataClass: "Info",
        tag: "Geo crossover",
        title: "Geometry, but also another field",
    },
    GAMES: {
        dataClass: "Info",
        tag: "Games",
        title: "Combinatorial games"
    },
    CLASSIC_GEOMETRY: {
        dataClass: "Info",
        tag: "Classic geometry",
        title: "Classic geometry problem",
    },
    PROJECTIVE_GEOMETRY: {
        dataClass: "Info",
        tag: "Projective geometry",
        title: "Projective geometry",
    },
    SECOND_MOMENT: {
        dataClass: "Info",
        tag: "Second moment",
        title: "Second moment method",
    },
}
export const ProblemButtonData = {
    NO_BUTTON: "no button",
    PDF_BUTTON: "pdf button",
    SKILLPAGE_BUTTON: "skillpage button",
}