/* global $ */

import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

import {AiFillYoutube} from "react-icons/ai"
import {Container, Row, Col} from "react-bootstrap"

import MyTooltip from '../MyTooltip/MyTooltip'

import "./MyButton.css"
import "../../global.css"
import "../../main.css"

import { toPath } from "../../functions/toPath"



function YoutubeButton({youtubeVideo}) {
  return (
    <div className="button">
      <Container fluid>
        <Row>
          <Col className="main-col">
            <span className="main-col-box">
              <a href={youtubeVideo.url} className="icon-box">
                <MyTooltip text={<div>Open Youtube video</div>} placement={"top"} arrow={false} slow={true}>
                  <div>
                    <AiFillYoutube size={70} className="clickable-icon"/>
                  </div>
                </MyTooltip>
              </a>
              <a href={youtubeVideo.url} className="title-link">
                <div className="title-box">
                  {youtubeVideo.title}
                </div>
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default YoutubeButton