import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'

import {FaFileCode} from "react-icons/fa"

import {Container, Row, Col} from "react-bootstrap"

import MyTooltip from '../MyTooltip/MyTooltip'

import "./MyButton.css"
import "../../global.css"
import "../../main.css"

import { toPath } from "../../functions/toPath"



function CppButton({file}) {
  return (
    <div className="button">
      <Container fluid>
        <Row>
          <Col className="main-col">
            <span className="main-col-box">
              <a href={process.env.PUBLIC_URL + "/problem-code/" + file.fileName} download className="icon-box">
                <MyTooltip text={<div>Download implementation</div>} placement={"top"} arrow={false} slow={true}>
                  <div>
                    <FaFileCode size={70} className="clickable-icon"/>
                  </div>
                </MyTooltip>
              </a>
              <a href={process.env.PUBLIC_URL + "/problem-code/" + file.fileName} download className="title-link">
                <div className="title-box">
                  {file.title}
                </div>
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CppButton